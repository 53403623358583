import { useState, useEffect } from "react";

export const useOnScreen = (ordersShow: any) => {
  let data: any = [];

  const [isIntersecting, setIntersecting] = useState([]);
  const callback = (entries: any, observer: any) => {
    entries.map((item: any) => {
      data.push(item.isIntersecting);
      return null;
    });
    setIntersecting(data);
  };
  const observer = new IntersectionObserver(callback, {});

  useEffect(() => {
    if (ordersShow.length > 0) {
      ordersShow.map((item: any) => {
        let element: any = document.getElementById(item._id);
        if (element) {
          observer.observe(element);
        }
        return null;
      });
    } else {
      setIntersecting([]);
    }

    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersShow]);

  return isIntersecting;
};
