import React from "react";
import { useSelector } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import LockOpen from "@material-ui/icons/LockOpen";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";

import { PasswordResetInitialValues, PasswordResetProps } from "./interfaces";
import { passwordResetValidation } from "./validation";

import { AuthServices } from "../../../services/authService";
import { useAlert } from "../../../hooks/useAlert";
import { useLoader } from "../../../hooks/useLoader";
import { AlertEnum } from "actions/alertActions/alertActionTypes";

import { useStyles } from "./styles";
import { RootStore } from "store";

const PasswordReset = ({ closeModal }: PasswordResetProps) => {
  const { auth } = useSelector((state: RootStore) => state);
  const classes = useStyles();
  const alert = useAlert();
  const [load, loaded] = useLoader();

  const passwordResetInitialValues = {
    password: "",
    repeatedPassword: "",
  };

  const handleSubmit = async (values: PasswordResetInitialValues) => {
    const { password } = values;
    const { fleetClient } = auth;
    load();
    try {
      if (fleetClient) {
        await AuthServices.updatePassword(fleetClient._id, password);
        alert("Password updated susccessfully");
      }
      loaded();
    } catch (err) {
      alert(
        "Somenthing went wrong when updating the password",
        AlertEnum.error,
      );
      loaded();
    }
  };

  return (
    <div className={classes.root}>
      <Paper elevation={3}>
        <Typography
          variant="h5"
          align="center"
          classes={{ root: classes.headingStyles }}
        >
          <LockOpen />
          Password Reset
        </Typography>
        <Formik
          validationSchema={passwordResetValidation}
          initialValues={passwordResetInitialValues}
          onSubmit={(values, _) => {
            handleSubmit(values);
          }}
        >
          <Form className={classes.formStyles}>
            <Field
              component={TextField}
              type="password"
              name="password"
              variant="outlined"
              label="New password"
              className={classes.fieldStyles}
            />
            <Field
              component={TextField}
              type="password"
              name="repeatedPassword"
              variant="outlined"
              label="Repeat password"
              className={classes.fieldStyles}
            />
            <Button
              variant="contained"
              type="submit"
              classes={{
                root: classes.buttonStyle,
              }}
            >
              Confirm Password Reset
            </Button>
          </Form>
        </Formik>
        <Typography
          variant="subtitle1"
          classes={{ root: classes.headingStyles }}
        >
          Don't want to reset your password?
          <span onClick={closeModal} className={classes.closeStyles}>
            Close
          </span>
        </Typography>
      </Paper>
    </div>
  );
};

export default PasswordReset;
