import axios, { AxiosRequestConfig, AxiosPromise } from "axios";

import { store } from "../store";

const axiosBaseOptions = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  return {
    baseURL,
    ...config,
  };
};

axios.interceptors.request.use((req) => {
  const token = store.getState().auth.accessToken;
  if (token) {
    req.headers.authorization = `Bearer ${token}`;
    return req;
  }
  return req;
});

const request = <T>(config: AxiosRequestConfig): AxiosPromise<T> =>
  axios.request<T>(axiosBaseOptions(config));

const get = <T>(
  url: string = "",
  config: AxiosRequestConfig = {},
): AxiosPromise<T> => request({ url, ...config, method: "GET" });

const post = <T>(
  url: string = "",
  config: AxiosRequestConfig,
): AxiosPromise<T> => request({ url, ...config, method: "POST" });

const put = <T>(
  url: string = "",
  config: AxiosRequestConfig,
): AxiosPromise<T> => request({ url, ...config, method: "PUT" });

const patch = <T>(
  url: string = "",
  config: AxiosRequestConfig,
): AxiosPromise<T> => request({ url, ...config, method: "PATCH" });

const del = <T>(
  url: string = "",
  config: AxiosRequestConfig,
): AxiosPromise<T> => request({ url, ...config, method: "DELETE" });

export { get, post, put, patch, del };
