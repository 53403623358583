export const INIT_SIGNUP = "INIT_SIGNUP";
export const SET_COMPANY = "ADD_COMPANY";
export const ADD_LOCATION = "ADD_LOCATION";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const SIGNUP_CLEAR = "SIGNUP_CLEAR";
export const GET_SIGNUP = "GET_SIGNUP";
export const GET_SIGNUP_ERROR = "GET_SIGNUP_ERROR";
export interface InitSignup {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  companyName: string;
}
export interface InitSignupAction {
  type: typeof INIT_SIGNUP;
  payload: InitSignup;
}
export interface CompanySignup {
  email: string;
  phone: number;
  company: string;
  companyAddress: string;
}
export interface setCompany {
  type: typeof SET_COMPANY;
  payload: CompanySignup;
}
export interface addLocation {
  type: typeof ADD_LOCATION;
  payload: ILocation[];
}

export interface SignupFail {
  type: typeof SIGNUP_FAIL;
}
export interface SignupClear {
  type: typeof SIGNUP_CLEAR;
}
export interface GetSignupError {
  type: typeof GET_SIGNUP_ERROR;
  payload: boolean;
}

export interface GetSignup {
  type: typeof GET_SIGNUP;
  payload: SignUp;
}
export interface ICompany {
  name: string;
  address: string;
  phone: number;
  locations?: ILocation[];
}
export interface ILocation {
  name: string;
  address: IAddress;
}
export interface IAddress {
  line1: string;
  line2: string;
  city: string;
  zipcode: number | null;
  state: string;
  latitude: number | null;
  longitude: number | null;
}
export interface SignupPostBody {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  companyName: string;
  company: ICompany;
}

export interface SignupPost {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  companyName: string;
  company: string;
}

export type SignupDispatchTypes =
  | InitSignupAction
  | SignupFail
  | addLocation
  | setCompany
  | SignupClear
  | GetSignup
  | GetSignupError;

export interface SignUp {
  signupRequest: {
    _id: string;
    email: string;
    isSeen: boolean;
    completeSignup: boolean;
    company: string;
    nonce: string;
    createdAt: string;
    updatedAt: string;
    locations: string[];
    __v: string;
  };
  fleetsLocations: string[];
  company: {
    _id: string;
    name: string;
    notes: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
  };
}
