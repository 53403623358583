import { combineReducers } from "redux";
import authReducer from "./authReducer";
import signupReducer from "./signupReducer";
import alertReducer from "./alertReducer";
import loaderReducer from "./loaderReducer";
import addressesReducer from "./addressesReducer";
import ordersReducer from "./ordersReducers";
import locationReducer from "./locationReducer";
import fleetClientRecucer from "./fleetClientReducer";
import loadingDrawerReducer from "./loadingDrawerReducer";
import pointFirebaseReducer from "./pointFirebaseReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  signup: signupReducer,
  alert: alertReducer,
  loading: loaderReducer,
  locationAddresses: addressesReducer,
  assetOrders: ordersReducer,
  locations: locationReducer,
  fleetClient: fleetClientRecucer,
  loadingDrawerReducer: loadingDrawerReducer,
  pointFirebaseReducer: pointFirebaseReducer,
});

export default rootReducer;
