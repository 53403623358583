import { Dispatch } from "redux";
import { showAlert } from "../alertActions";
import { FleetLocationServices } from "../../services/FleetLocationServices";
import {
  SET_ADDRESSES,
  CLEAR_ADDRESSES,
  AddressDispatchTypes,
} from "./addressesActionTypes";
import { AlertEnum } from "actions/alertActions/alertActionTypes";

export const getAddresses = (userId: string) => async (
  dispatch: Dispatch<AddressDispatchTypes>,
) => {
  try {
    const addresses: any[] = await FleetLocationServices.getAddresses(userId);
    addresses.push({
      locationId: "all",
      address: "All Locations",
      city: "All Locations",
      state: "All Locations",
      zipcode: "",
      coordinates: null,
    });
    dispatch({
      type: SET_ADDRESSES,
      payload: addresses,
    });
  } catch (err) {
    showAlert("Something went wrong when fetching addresses", AlertEnum.error);
  }
};

export const clearAddresses = () => async (
  dispatch: Dispatch<AddressDispatchTypes>,
) => {
  dispatch({ type: CLEAR_ADDRESSES });
};
