import { format, isBefore } from "date-fns";

const months: { [key: string]: string } = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

export const mapNumberToMonth = (monthNumber: number): string => {
  return months[monthNumber];
};

export const scheduleString = (start: Date, end: Date): string => {
  const hourStart = format(new Date(start), "hh:mm aaaaa'm'");
  const hourEnd = format(new Date(end), "hh:mm aaaaa'm'");
  return `${hourStart} - ${hourEnd}`;
};

export const formatDate = (date: Date): string => {
  return format(new Date(date), "MMMM d, yyyy");
};

export const formatDateHour = (date: Date): string => {
  return format(new Date(date), "MMMM d, yyyy hh:mm:ss");
};

export const getLastDayPreviusMonth = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), 0).getDate();
};

export const getFirstDayMonth = (date: Date, _: string = "day") => {
  const result = new Date(date.getFullYear(), date.getMonth(), 1);

  if (_ === "day") return result.getDay();
  else if (_ === "dd MMM yyyy") return format(result, "dd MMM yyyy");
  return result;
};

export const getLastDayMonth = (date: Date, _: string = "day") => {
  const result = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  if (_ === "day") return result.getDate();
  else if (_ === "dd MMM yyyy") return format(result, "dd MMM yyyy");
  return result;
};

export const isBeforeToday = (date: Date) => {
  const today = new Date();
  today.setDate(today.getDate() - 2);
  today.setHours(23, 59, 59, 0);
  return isBefore(date, today);
};
