import { LOADED, LOADING } from "../actions/loaderActions/loaderActionTypes";

type IDefaultState = boolean;
const defaultState: IDefaultState = false;

const loaderReducer = (
  state: IDefaultState = defaultState,
  action: any,
): IDefaultState => {
  switch (action.type) {
    case LOADED:
      return false;
    case LOADING:
      return true;
    default:
      return state;
  }
};

export default loaderReducer;
