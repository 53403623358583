import firebase from "firebase/compat/app";
import "firebase/compat/messaging";

const FIREBASE_CONFIG = {
  apiKey: "AIzaSyD8R6MBF7XjwupaYf6MrCzVzsovwCGF7ko",
  authDomain: "nfuel-143421.firebaseapp.com",
  databaseURL: "https://nfuel-143421.firebaseio.com",
  projectId: "nfuel-143421",
  storageBucket: "nfuel-143421.appspot.com",
  messagingSenderId: "882025151738",
  appId: "1:882025151738:web:4c1a263ee3aa0cf2bf5d43",
  measurementId: "G-MK0FV71MDH",
};

const AUTHORIZATION_KEY = `key=AAAAzVzLvPo:APA91bFR8yj59K0AOYhwaScZ9dh649uQu-_vczhm_42ifsBxDGZe6wmvQuf51EBwfYuTKLP0rcxxd9WnYamVqoxNKvbVOanCdtDMXBWVysH3QFVd5XNx-dYhn9AvfoeaQsQJ3XZQWhxx`;
const VAPID_KEY =
  "BOulfx8852OI0fOnBUAHn8IKmYtWYglu7UpniyLR8YBpLMVGu6FTc-Uzy9MIgFDMGqut3VclCX1DR5sJGQiSiEI";

firebase.initializeApp(FIREBASE_CONFIG);

const messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;

export const getToken = async () => {
  try {
    const currentToken = await messaging.getToken({
      vapidKey: VAPID_KEY,
    });
    if (currentToken) {
      console.log("Current Firebase Token for Client: ", currentToken);
      return currentToken;
    } else {
      console.log(
        "No registration token available. Request permission to generate one.",
      );
    }
  } catch (err) {
    console.error("An error occurred while retrieving token. ", err);
  }
};

export const onMessageListener = () =>
  new Promise((resolve, reject) => {
    if (!messaging) reject("Browser not supported");
    else
      messaging.onMessage((payload) => {
        resolve(payload);
      });
  });

function subscribeTokenToTopic(token, topic) {
  fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, {
    method: "POST",
    headers: new Headers({
      Authorization: AUTHORIZATION_KEY,
    }),
  })
    .then((response) => {
      if (response.status < 200 || response.status >= 400) {
        console.log(response.status, response);
      }
      console.log(`"${topic}" is subscribed`);
    })
    .catch((error) => {
      console.error(error.result);
    });

  return true;
}
