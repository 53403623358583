import {
  GET_LOCATION,
  LOADING_LOCATION,
  LocationActionsDispatchTypes,
  LOCATION_LOADED,
  SET_LOCATION_SELECTED,
} from "actions/locationActions/locationActionsTypes";

interface IDefaultState {
  loading: boolean;
  chosenLocation: any;
  selectedLocation: any;
}

const defaultState: IDefaultState = {
  loading: false,
  chosenLocation: [],
  selectedLocation: null,
};

const locationReducer = (
  state = defaultState,
  action: LocationActionsDispatchTypes,
): typeof defaultState => {
  switch (action.type) {
    case GET_LOCATION:
      return {
        ...state,
        chosenLocation: action.payload,
        // selectedLocation: action.payload._id,
      };
    case SET_LOCATION_SELECTED: {
      return {
        ...state,
        selectedLocation: action.payload,
      };
    }
    case LOADING_LOCATION:
      return {
        ...state,
        loading: true,
      };
    case LOCATION_LOADED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default locationReducer;
