import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  weekView: {
    minWidth: "100%",
    display: "block",
    boxSizing: "border-box",
    marginLeft: " 10px",
    marginRight: "10px",
  },
  root: {
    "& .MuiTableCell-head": {
      textAlign: "center",
      fontWeight: "bold",
    },
    "& .MuiTableCell-root": {
      padding: "5px",
    },
    "& .day-content": {
      marginTop: "5px",
      minHeight: "121px",
    },
    "& .day-content2": {
      marginTop: "5px",
      height: "auto",
    },
    "& .skip-service": {
      display: "flex",
      justifyContent: "flex-end",
      "& p": {
        backgroundColor: "#f66300",
        padding: "0 7px",
        borderRadius: "6px",
        color: "white",
        cursor: "pointer",
      },
    },
    "& .day-number": {
      display: "block",
      textAlign: "center",
      width: "25.82px",
      height: "25.82px",
      padding: "2px 3px",
      margin: "auto",
    },
    "& .selected-day .day-number": {
      backgroundColor: "#ff5100",
      color: "#fff",
      borderRadius: "50%",
    },
  },

  itemCard: {
    "& .MuiLinearProgress-barColorPrimary": {
      background: "rgba(255, 255, 255, .6)",
    },
    "& .MuiLinearProgress-colorPrimary": {
      background: "rgba(0, 0, 0, .4)",
    },
  },

  serviced: {
    background: "#6dab99",
    color: "#dcf9f0",
  },
  partially_serviced: {
    background: "#fa8e46",
    color: "#f9e0d0",
  },
  paid: {
    background: "#4b6299",
    color: "#c4cbdd",
  },
  invoiced: {
    background: "#6dab99",
    color: "#eafbf6",
  },
  pending: {
    background: "#737e94",
    color: "#e4eaf7",
  },
  cancel_admin: {
    background: "#de0c0c",
  },
  cancel_consumer: {
    background: "#c96b6b",
    color: "#ffd0d0",
  },
  progress: {
    height: "9px",
  },
  textSm: {
    fontSize: "8px",
  },
});
