import React from "react";
import { useSelector } from "react-redux";
import { RootStore } from "store";
import { Spinner } from "components/loader";
import Alert from "../SharedComponents/Alert";
import PushNotification from "components/pushNotification";
import Routes from "../../routes";
import Loader from "../SharedComponents/Loader";

import "./App.css";

const App = () => {
  const { loading, loadingDrawerReducer } = useSelector(
    (state: RootStore) => state,
  );
  return (
    <>
      {loadingDrawerReducer && <Spinner />}
      {loading ? <Loader /> : <Routes />}
      <Alert />
      <PushNotification />
    </>
  );
};

export default App;
