import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  table: {
    width: "100%",
  },
  tHead: {
    background: (props: any) => props.background || "#333",
  },
  tHeadCell: {
    color: (props: any) => props.color || "#fff",
    fontWeight: "bold",
  },
  tRow: {
    paddingRight: "6rem",
    "&:nth-child(even)": {
      backgroundColor: "#F5F5F5",
    },
  },
  tRowWhite: {
    paddingRight: "6rem",
    "&:nth-child(even)": {
      backgroundColor: "white",
    },
  },
  addButton: {
    background: "#fff",
    color: "#000000",
    padding: ".5rem 1rem",
    float: "right",
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  search: {
    display: "flex",
    paddingLeft: "1rem",
    flexGrow: 1,
    marginBottom: "2rem",
  },
});
