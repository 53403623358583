import {
  INIT_SIGNUP,
  SET_COMPANY,
  ADD_LOCATION,
  SignupDispatchTypes,
  InitSignup,
  CompanySignup,
  ILocation,
  SIGNUP_CLEAR,
  GET_SIGNUP,
  SignUp,
  GET_SIGNUP_ERROR,
} from "../actions/signupAction/signupActionTypes";

export interface IDefaultState {
  signup: InitSignup | null;
  company: CompanySignup | null;
  location: ILocation[] | null;
  signupRequest: SignUp | null;
  signupRequestError: boolean;
}

const defaultState: IDefaultState = {
  signup: null,
  company: null,
  location: null,
  signupRequest: null,
  signupRequestError: false,
};

const signupReducer = (
  state: IDefaultState = defaultState,
  action: SignupDispatchTypes,
): IDefaultState => {
  switch (action.type) {
    case INIT_SIGNUP:
      return { ...state, signup: action.payload };
    case SET_COMPANY:
      return { ...state, company: action.payload };
    case ADD_LOCATION:
      return { ...state, location: action.payload };
    case GET_SIGNUP:
      return { ...state, signupRequest: action.payload };
    case GET_SIGNUP_ERROR:
      return { ...state, signupRequestError: action.payload };
    case SIGNUP_CLEAR:
      return { ...defaultState };
    default:
      return state;
  }
};

export default signupReducer;
