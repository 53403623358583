export const GET_ASSET_ORDERS = "GET_ASSET_ORDERS";

interface getAssetOrders {
  type: typeof GET_ASSET_ORDERS;
  payload: AssetOrder[];
}

export interface AssetOrder {
  _id: string;
  updatedAt: Date;
  createdAt: Date;
  driver: string;
  driverName: string;
  driverEmail: string;
  gallons: number;
  location: string;
  locationName: string;
  schedule: string;
  fuel: string;
  loc: string;
  clientId: string;
  doorImage: string | null;
  vinImage: string | null;
  make: string;
  model: string;
  tag: string;
  assetId: string;
  truck: string;
  truckName: string;
  perGallons: number;
  total: number;
  manualDate: Date | null;
  notes: string | null;
  status: string;
}

export type ordersDispatchTypes = getAssetOrders;
