import React, { lazy } from "react";
import { Route } from "react-router-dom";
import { PublicRoutes } from "./interfaces";

const SignUp = lazy(() => import("../pages/SignUp"));
const SignUpCompany = lazy(() => import("../pages/SignUp/SignupCompany"));
const SignUpLocation = lazy(() => import("../pages/SignUp/SignupLocation"));

const SignupRoutes = () => {
  return (
    <>
      <Route exact path={PublicRoutes.signup} component={SignUp} />
      <Route
        exact
        path={PublicRoutes.signupCompany}
        component={SignUpCompany}
      />
      <Route
        exact
        path={PublicRoutes.signupLocation}
        component={SignUpLocation}
      />{" "}
    </>
  );
};

export default SignupRoutes;
