import { useDispatch } from "react-redux";
import { startLoader, stopLoader } from "../actions/loaderActions";

type useLoaderReturn = [load: () => void, loaded: () => void];

export const useLoader = (): useLoaderReturn => {
  const dispatch = useDispatch();
  return [
    () => {
      dispatch(startLoader());
    },
    () => {
      dispatch(stopLoader());
    },
  ];
};
