import { showAlert } from "../actions/alertActions";
import { AlertEnum } from "../actions/alertActions/alertActionTypes";
import { useDispatch } from "react-redux";

type UseAlertReturn = (message: string, type?: string) => void;

export const useAlert = (): UseAlertReturn => {
  const dispatch = useDispatch();
  return (message, type = "success") =>
    dispatch(showAlert(message, typeMapper(type)));
};

const typeMapper = (type: string): AlertEnum => {
  switch (type) {
    case "error":
      return AlertEnum.error;
    case "info":
      return AlertEnum.info;
    case "warning":
      return AlertEnum.warning;
    default:
      return AlertEnum.success;
  }
};
