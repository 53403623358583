import { AssetOrder } from "actions/ordersActions/orderActiontypes";

import { scheduleString } from "./dateHelpers";

interface IAssetsCounts {
  totalOrders: number;
  unservedOrders: number;
  totalGallons: number;
  totalPrice: number;
  fuelTypes: any;
}

export const getAssetsCounts = (assetOrders: AssetOrder[]): IAssetsCounts => {
  let totalOrders = 0;
  let unservedOrders = 0;
  let totalPrice = 0;
  let totalGallons = 0;

  const fuelTypes: any = {};

  assetOrders &&
    assetOrders.forEach((order) => {
      if (!fuelTypes[order.fuel]) {
        fuelTypes[order.fuel] = {
          perGallon: 0,
          totalGallons: 0,
          totalPrice: 0,
          totalOrders: 0,
          unservedOrders: 0,
        };
      }

      fuelTypes[order.fuel] = {
        perGallon: order?.perGallons || 1.98,
        totalGallons:
          fuelTypes[order?.fuel]?.totalGallons + Number(order?.gallons) || 0,
        totalPrice:
          fuelTypes[order?.fuel]?.totalPrice + Number(order?.total) || 0,
        totalOrders: fuelTypes[order?.fuel]?.totalOrders + 1,
        unservedOrders: !order?.gallons
          ? fuelTypes[order?.fuel]?.unservedOrders + 1
          : fuelTypes[order?.fuel]?.unservedOrders,
      };

      totalGallons += Number(order?.gallons?.toFixed(2)) || 0;
      totalPrice += Number(order?.total?.toFixed(2)) || 0;
      totalOrders++;
      if (!order.gallons) unservedOrders++;
    });

  return {
    totalOrders: Number(totalOrders?.toFixed(2)),
    unservedOrders: Number(unservedOrders?.toFixed(2)),
    totalGallons: Number(totalGallons?.toFixed(2)),
    totalPrice: Number(totalPrice?.toFixed(2)),
    fuelTypes: fuelTypes,
  };
};
//TODO make the proper types
export const transformCalendarData = (orders: any[]) => {
  const result: any = {};
  let servicedCount = 0;
  if (orders) {
    if (orders.length > 0) {
      orders.forEach((order) => {
        const startDate = new Date(order.scheduledStartTime);
        const endDate = new Date(order.scheduledEndTime);

        const day = startDate.getDate();
        const month = startDate.getMonth();
        const year = startDate.getFullYear();
        const resultKey = `${year}-${month}-${day}`;
        if (!order?.assetOrders?.length) {
          order.assetOrders = [];
        }
        result[resultKey] = {
          details: order.assetOrders.reduce((acc: any, curr: any) => {
            acc[curr.fuel] = {
              gallons:
                (!isNaN(acc[curr.fuel]?.gallons)
                  ? acc[curr.fuel]?.gallons
                  : 0) + !isNaN(curr.gallons)
                  ? Number(curr.gallons)
                  : 0,
              perGallon: !isNaN(curr.perGallon) ? curr.perGallon : 1.98,
              total:
                (!isNaN(acc[curr.fuel]?.total) ? acc[curr.fuel]?.total : 0) +
                (!isNaN(curr.total) ? Number(curr.total) : 0),
            };
            return acc;
          }, {}),
          totalAssets: order.assetOrders.length,
          status: order.status,
          _id: order._id,
          schedule: scheduleString(startDate, endDate),
          setCountStatus: orderSetStatus(order.assetOrders, order.status),
          detailsPaid: order.detailsPaid || [],
          notes: order.notes,
        };

        servicedCount =
          servicedCount +
          order.assetOrders.filter(
            ({ status }: any) =>
              String(status).toLowerCase().trim() === "serviced" ||
              String(status).toLowerCase().trim() === "paid" ||
              String(status).toLowerCase().trim() === "invoiced",
          ).length;
      });
    }
  }

  result.servicedCount = servicedCount;

  return result;
};

export const transformCalendarDataWhitoutKeys = (orders: any[]) => {
  const result: any = [];
  let servicedCount = 0;
  if (orders) {
    if (orders.length > 0) {
      orders.forEach((order) => {
        const startDate = new Date(order.scheduledStartTime);
        const endDate = new Date(order.scheduledEndTime);

        const day = startDate.getDate();
        const month = startDate.getMonth();
        const year = startDate.getFullYear();
        const resultKey = `${year}-${month}-${day}`;
        if (!order?.assetOrders?.length) {
          order.assetOrders = [];
        }
        result[resultKey] = {
          details: order.assetOrders.reduce((acc: any, curr: any) => {
            acc[curr.fuel] = {
              gallons:
                (!isNaN(acc[curr.fuel]?.gallons)
                  ? acc[curr.fuel]?.gallons
                  : 0) + !isNaN(curr.gallons)
                  ? Number(curr.gallons)
                  : 0,
              perGallon: !isNaN(curr.perGallon) ? curr.perGallon : 1.98,
              total:
                (!isNaN(acc[curr.fuel]?.total) ? acc[curr.fuel]?.total : 0) +
                (!isNaN(curr.total) ? Number(curr.total) : 0),
            };
            return acc;
          }, {}),
          totalAssets: order.assetOrders.length,
          status: order.status,
          _id: order._id,
          schedule: scheduleString(startDate, endDate),
          setCountStatus: orderSetStatus(order.assetOrders, order.status),
          detailsPaid: order.detailsPaid || [],
          notes: order.notes,
        };
      });
    }
  }
  return Object.values(result);
};

const orderSetStatus = (data: any, status: string) => {
  let statusCount = 0 as number;
  data.map((item: any) => {
    if (status === item.status) {
      statusCount++;
    }
    return null;
  });
  return (statusCount * 100) / data.length;
};
