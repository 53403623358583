import React, { useEffect, useState } from "react";
import {
  Container,
  Button,
  ButtonGroup,
  Paper,
  Drawer,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ConfirmDialog from "components/SharedComponents/ConfirmDialog";
import {
  transformCalendarData,
  transformCalendarDataWhitoutKeys,
} from "helpers/assetsHelpers";
import View from "./View";
import { GetDaysOf, GetFirstDayOfWeek, GetLastDayOfWeek } from "./utils";
import { addWeeks, addMonths } from "date-fns";
import { Order } from "services/OrderService";
import { skipService } from "actions/ordersActions";
import { getFirstDayMonth, getLastDayMonth } from "helpers/dateHelpers";
import { useStyles } from "./schedular.styles";
import { AlertEnum } from "actions/alertActions/alertActionTypes";
import { useAlert } from "hooks/useAlert";
import OrderDetails from "pages/Orders/order/OrderDetails";
import CloseIcon from "@material-ui/icons/Close";
import { Asset } from "actions/locationActions/locationActionsTypes";
import { useStyle } from "./styles";
import { useDispatch } from "react-redux";
import { startLoaderDrawer, stopLoaderDrawer } from "actions/loaderActions";

const Scheduler: React.FC<any> = () => {
  const classOrder = useStyle();
  const [dateRange, setDateRange] = React.useState<any>({
    startDate: getFirstDayMonth(new Date(), "dd MMM yyyy"),
    endDate: getLastDayMonth(new Date(), "dd MMM yyyy"),
  });
  const dispatch = useDispatch();
  const alert = useAlert();
  const classes = useStyles();
  const [orders, setOrders] = useState([]);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [calenderView, setCalenderView] = useState<"month" | "week">("month");
  const [refetch, setRefetch] = useState<boolean>(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [frameDrawer, setFrameDrawer] = useState("");
  const [selectedVehicle, setSelectedVehicle] = useState<Asset | null>(null);
  const [showIcon, setShowIcon] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const [dataShow, setDataShow] = useState([]);

  const todayClick = () => {
    setDateRange({
      startDate: getFirstDayMonth(new Date(), "dd MMM yyyy"),
      endDate: getLastDayMonth(new Date(), "dd MMM yyyy"),
    });
  };

  const handleArrowClick = (fwd: boolean) => {
    setFirstLoad(false);
    setDataShow([]);
    if (calenderView === "month")
      setDateRange({
        startDate: fwd
          ? getFirstDayMonth(
              addMonths(new Date(dateRange.startDate), 1),
              "dd MMM yyyy",
            )
          : getFirstDayMonth(
              addMonths(new Date(dateRange.startDate), -1),
              "dd MMM yyyy",
            ),
        endDate: fwd
          ? getLastDayMonth(
              addMonths(new Date(dateRange.endDate), 1),
              "dd MMM yyyy",
            )
          : getLastDayMonth(
              addMonths(new Date(dateRange.endDate), -1),
              "dd MMM yyyy",
            ),
      });
    else
      setDateRange({
        startDate: fwd
          ? GetFirstDayOfWeek(addWeeks(new Date(dateRange.startDate), 1))
          : GetFirstDayOfWeek(addWeeks(new Date(dateRange.startDate), -1)),
        endDate: fwd
          ? GetLastDayOfWeek(addWeeks(new Date(dateRange.endDate), 1))
          : GetLastDayOfWeek(addWeeks(new Date(dateRange.endDate), -1)),
      });
  };

  const toggleCalenderView = (view: "month" | "week") => {
    setDateRange(() => ({
      startDate:
        view === "week"
          ? GetFirstDayOfWeek(new Date())
          : getFirstDayMonth(new Date(), "dd MMM yyyy"),
      endDate:
        view === "week"
          ? GetLastDayOfWeek(new Date())
          : getLastDayMonth(new Date(), "dd MMM yyyy"),
    }));
    setCalenderView((_) => view);
  };

  const handleDialogConfirm = async (confirmed: boolean) => {
    if (confirmed) {
      const found = orders.filter(({ id }: any) => id === selectedItem?._id);
      if (found) {
        try {
          await skipService(found);
          setRefetch(!refetch);
          alert("Order skipped successfully");
        } catch (ex) {
          alert("Error while skipping order", AlertEnum.error);
        }
      }
    }
    setOpen(() => false);
  };

  const handleDayClick = async (item: any) => {
    if (!item) return;
    // if (item?.status === "pending") {
    //   setSelectedItem(() => item);
    //   setOpen(() => true);
    // } else {
    setSelectedItem(() => item);

    let data = {} as any;
    try {
      data = await Order.getLocationOrders(item._id);
    } catch (e) {}
    setSelectedVehicle(data);
    toggleDrawer("details");
    // }
  };

  useEffect(() => {
    const loadData = async () => {
      dispatch(startLoaderDrawer());
      try {
        let data: any = await Order.getClientOrders(
          new Date(dateRange.startDate),
          new Date(dateRange.endDate),
        );
        setOrders(data.data);
        dispatch(stopLoaderDrawer());
      } catch (e) {
        dispatch(stopLoaderDrawer());
      }
    };
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, setOrders]);

  const toggleDrawer = (frame: String = "") => {
    setFrameDrawer(`${frame}`);
    setShowDrawer(!showDrawer);
  };

  return (
    <Container className={calenderView === "week" ? classes.weekView : ""}>
      <Paper>
        <div className="flex justify-between p-5">
          <div className="space-x-5">
            <ButtonGroup aria-label="outlined primary button group">
              <Button onClick={() => handleArrowClick(false)}>
                <ArrowBackIosIcon style={{ color: "#ff5100" }} />
              </Button>
              <Button onClick={() => handleArrowClick(true)}>
                <ArrowForwardIosIcon style={{ color: "#ff5100" }} />
              </Button>
            </ButtonGroup>
            <label className="font-semibold" style={{ color: "#ff5100" }}>
              {dateRange.startDate + " - " + dateRange.endDate}
            </label>
            <Button
              variant="contained"
              classes={{
                root: classOrder.buttonStyle,
              }}
              onClick={() => todayClick()}
            >
              TODAY
            </Button>
          </div>
          <ButtonGroup aria-label="outlined primary button group">
            <Button
              style={
                calenderView === "month"
                  ? { backgroundColor: "#ff5100", color: "white" }
                  : {}
              }
              onClick={() => {
                toggleCalenderView("month");
                setShowIcon(false);
              }}
            >
              Month
            </Button>
            <Button
              style={
                calenderView !== "month"
                  ? { backgroundColor: "#ff5100", color: "white" }
                  : {}
              }
              onClick={() => {
                toggleCalenderView("week");
                setShowIcon(false);
              }}
            >
              Week
            </Button>
          </ButtonGroup>
        </div>
        <div>
          {
            <>
              <View
                activeDate={new Date(dateRange.startDate)}
                onDayClick={(item: any) => handleDayClick(item)}
                orders={transformCalendarData(orders)}
                ordersShow={transformCalendarDataWhitoutKeys(orders)}
                view={calenderView}
                weeks={GetDaysOf(new Date(dateRange.startDate), calenderView)}
                setShowIcon={(value: any) => setShowIcon(value)}
                showIcon={showIcon}
                firstLoad={firstLoad}
                setFirstLoad={(value: any) => setFirstLoad(value)}
                setDataShow={(value: any) => setDataShow(value)}
                dataShow={dataShow}
              />
            </>
          }
        </div>
        <ConfirmDialog
          open={open}
          bodyConfirmDialog="Are you sure that you want to skip this service?"
          onClose={handleDialogConfirm}
        />
        {showDrawer && (
          <Drawer
            anchor={"right"}
            open={showDrawer}
            onClose={() => toggleDrawer()}
          >
            <div className="pt-4 px-6 drawer">
              <div className="flex justify-between items-center">
                <div className="uppercase">
                  {frameDrawer === "details" && (
                    <label className="text-l font-semibold">
                      Order Details
                    </label>
                  )}
                </div>
                <Button onClick={() => toggleDrawer()}>
                  <CloseIcon />
                </Button>
              </div>
              <div>
                {frameDrawer === "details" && (
                  <OrderDetails asset={selectedVehicle} />
                )}
              </div>
            </div>
          </Drawer>
        )}
      </Paper>
    </Container>
  );
};
export default Scheduler;
