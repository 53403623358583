import { Dispatch } from "redux";
import { GET_ASSET_ORDERS } from "actions/ordersActions/orderActiontypes";
import { Order } from "services/OrderService";
import { startLoaderDrawer, stopLoaderDrawer } from "actions/loaderActions";

export const getAssetOrders = (
  locationId: string | undefined = undefined,
  start: Date,
  end: Date | undefined = undefined,
  sortType: any,
  page: any,
  pageSize: any,
) => async (dispatch: Dispatch<any>) => {
  dispatch(startLoaderDrawer());
  try {
    const orders = await Order.getClientOrders(
      start,
      end,
      locationId,
      sortType,
      page,
      pageSize,
    );
    dispatch({
      type: GET_ASSET_ORDERS,
      payload: orders,
    });
    dispatch(stopLoaderDrawer());
  } catch (e) {
    dispatch(stopLoaderDrawer());
  }
};

export const skipService = async (orders: any) => {
  const promises = orders
    .filter((order: any) => order.status === "pending")
    .map((order: any) => {
      return Order.deleteOrder(order.id);
    });
  return await Promise.all(promises);
};
