import {
  SET_ADDRESSES,
  CLEAR_ADDRESSES,
  AddressDispatchTypes,
  LocationAddress,
} from "../actions/addressesActions/addressesActionTypes";

const defaultState: LocationAddress[] = [];

const addressReducer = (
  state = defaultState,
  action: AddressDispatchTypes,
): typeof defaultState => {
  switch (action.type) {
    case SET_ADDRESSES:
      return action.payload;
    case CLEAR_ADDRESSES:
      return defaultState;
    default:
      return state;
  }
};

export default addressReducer;
