import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Button } from "@material-ui/core";
import { onMessageListener } from "../../firebase";
import { updatePointReducer } from "actions/pointReducerActions";

const PushNotification = () => {
  const dispatch = useDispatch();

  onMessageListener()
    .then((payload) => {
      if (payload.data?.body === "updateOrder") {
        dispatch(updatePointReducer("Orders", true));
      }
    })
    .catch(console.log);

  return null;
};

export default PushNotification;
