export const ALERT_SUCCESS = "ALERT_SUCCESS";
export const ALERT_INFO = "ALERT_INFO";
export const ALERT_ERROR = "ALERT_ERROR";
export const ALERT_WARNING = "ALERT_WARNING";
export const ALERT_CLEAR = "ALERT_CLEAR";

export interface Success {
  type: typeof ALERT_SUCCESS;
  payload: AlertPayload;
}
export interface Info {
  type: typeof ALERT_INFO;
  payload: AlertPayload;
}
export interface Error {
  type: typeof ALERT_ERROR;
  payload: AlertPayload;
}

export interface Clear {
  type: typeof ALERT_CLEAR;
}

export interface AlertPayload {
  message: string;
}

export interface Warning {
  type: typeof ALERT_WARNING;
  payload: AlertPayload;
}

export enum AlertEnum {
  success = "success",
  info = "info",
  error = "error",
  warning = "warning",
}

export type AlertDispatchTypes = Success | Info | Error | Warning | Clear | any;
