import PaymentIcon from "@material-ui/icons/Payment";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import ArticleIcon from "@mui/icons-material/Article";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import "./index.css";

export const sidebarOptions = [
  {
    icon: <AutoAwesomeMosaicIcon className="MuiIcon-colorPrimary" />,
    iconActive: <AutoAwesomeMosaicIcon className="MuiIcon-colorSecondary" />,
    item: "Dashboard",
    link: "/dashboard" || "/",
  },
  {
    icon: <EventAvailableIcon className="MuiIcon-colorPrimary" />,
    iconActive: <EventAvailableIcon className="MuiIcon-colorSecondary" />,
    item: "Schedule",
    link: "/scheduler",
  },
  {
    icon: <DirectionsCarIcon className="MuiIcon-colorPrimary" />,
    iconActive: <DirectionsCarIcon className="MuiIcon-colorSecondary" />,
    item: "Assets",
    link: "/vehicles",
  },
  {
    icon: <ArticleIcon className="MuiIcon-colorPrimary" />,
    iconActive: <ArticleIcon className="MuiIcon-colorSecondary" />,
    item: "Orders",
    link: "/orders",
  },
  //Disabled section for V1:
  // {
  //   icon: icons.Orders,
  //   iconActive: icons.OrdersOrange,
  //   item: "Recurrent Orders",
  //   link: "/recurrent-orders",
  // },
  {
    icon: <PaymentIcon className="MuiIcon-colorPrimary" />,
    iconActive: <PaymentIcon className="MuiIcon-colorSecondary" />,
    item: "Invoices",
    link: "/invoices",
  },
  {
    icon: <HelpCenterIcon className="MuiIcon-colorPrimary" />,
    iconActive: <HelpCenterIcon className="MuiIcon-colorSecondary" />,
    item: "Help",
    link: "/help",
  },
];
