import { Dispatch } from "redux";
import {
  ALERT_ERROR,
  ALERT_SUCCESS,
  ALERT_WARNING,
  ALERT_INFO,
  ALERT_CLEAR,
  AlertDispatchTypes,
  AlertEnum,
} from "./alertActionTypes";

export const showAlert = (message: string, priority: AlertEnum) => async (
  dispatch: Dispatch<AlertDispatchTypes>,
) => {
  switch (priority) {
    case "success":
      dispatch({
        type: ALERT_SUCCESS,
        payload: { message },
      });
      break;
    case "warning":
      dispatch({
        type: ALERT_WARNING,
        payload: { message },
      });
      break;
    case "error":
      dispatch({
        type: ALERT_ERROR,
        payload: { message },
      });
      break;
    case "info":
      dispatch({
        type: ALERT_INFO,
        payload: { message },
      });
      break;
    default:
      break;
  }
  dispatch(clearAlert(2000));
};

const clearAlert = (timeout: number = 4000) => async (
  dispatch: Dispatch<AlertDispatchTypes>,
) => {
  setTimeout(() => {
    dispatch({ type: ALERT_CLEAR });
  }, timeout);
};
