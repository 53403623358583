import jwt_decode from "jwt-decode";
import { Dispatch } from "redux";
import { AlertEnum } from "../alertActions/alertActionTypes";
import { showAlert } from "../alertActions";
import { startLoader, stopLoader } from "../loaderActions";
import {
  LOGIN_SUCEEDED,
  LOGIN_FAILED,
  LOGOUT,
  LoginDispatchTypes,
  IUserInfo,
} from "./authActionTypes";

import { AuthServices } from "../../services/authService";
import { clearAddresses } from "actions/addressesActions";
import { SIGNUP_CLEAR } from "actions/signupAction/signupActionTypes";

export const loginAction = (email: string, password: string) => async (
  dispatch: Dispatch<LoginDispatchTypes>,
) => {
  dispatch(startLoader());
  try {
    const { token, fleetClient } = await AuthServices.login(email, password);
    const accessToken = token.access_token;
    const decodedUser: IUserInfo = jwt_decode(token.id_token);
    dispatch(stopLoader());
    dispatch({
      type: LOGIN_SUCEEDED,
      payload: { accessToken, decodedUser, fleetClient },
    });
    dispatch({ type: SIGNUP_CLEAR });
    dispatch(showAlert("Logged in successfully", AlertEnum.success));
  } catch (err) {
    dispatch(stopLoader());
    dispatch({ type: LOGIN_FAILED });
    dispatch(showAlert("Wrong username or password", AlertEnum.error));
  }
};

export const logoutAction = () => async (
  dispatch: Dispatch<LoginDispatchTypes>,
) => {
  AuthServices.logout();
  dispatch({ type: LOGOUT });
  dispatch(showAlert("Logged out successfully", AlertEnum.success));
  dispatch(clearAddresses());
};
