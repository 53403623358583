import {
  AssetOrder,
  GET_ASSET_ORDERS,
  ordersDispatchTypes,
} from "actions/ordersActions/orderActiontypes";

interface IDefaultState {
  currentDay: AssetOrder[];
}

const defaultState: IDefaultState = {
  currentDay: [],
};

const ordersReducer = (
  state = defaultState,
  action: ordersDispatchTypes,
): typeof defaultState => {
  switch (action.type) {
    case GET_ASSET_ORDERS:
      return {
        ...defaultState,
        currentDay: action.payload,
      };
    default:
      return state;
  }
};

export default ordersReducer;
