import React, { useState } from "react";
import TableHeader from "../../../../components/Table/TableHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { schema } from "./schema";
import { useStyles } from "../../../../components/Table/styles";
import { useStyle } from "./styles";
import { fuelTypes } from "../../../Vehicles/vehicle/CreateVehicle/info";
import { Paper, Card, Grid, Button } from "@material-ui/core";
import { formatDateHour } from "../../../../helpers/dateHelpers";
import { Order } from "../../../../services/OrderService";
import { showAlert } from "actions/alertActions";
import { AlertEnum } from "actions/alertActions/alertActionTypes";
import { useDispatch } from "react-redux";
import ConfirmDialog from "components/SharedComponents/ConfirmDialog";
import { StatusLabel } from "../../statusLabel";
import { styles } from "utils/styles";
import { FleetLocationServices } from "services/FleetLocationServices";
import { startLoaderDrawer, stopLoaderDrawer } from "actions/loaderActions";

const OrderDetails = ({ asset }: any) => {
  const [open, setOpen] = useState<boolean>(false);

  const Styles = styles();

  const dispatch = useDispatch();
  const classes = useStyles({ background: "black" });
  const classOrder = useStyle();
  const [filteredData, setFilteredData] = useState(asset.assetOrders);
  const [showEdit, setShowEdit] = useState(false);

  const editAssets = () => {
    setShowEdit(!showEdit);
  };
  const filterData = (data: any) => {
    let newData = [...filteredData];
    data.map((item: any, indexData: any) => {
      fuelTypes.map((itemFuel, index) => {
        if (itemFuel.value === item.fuel.toLowerCase()) {
          newData[indexData].fuel = itemFuel.label.toUpperCase();
        }
        if (item.showAssetOrder === undefined) {
          newData[indexData].showAssetOrder = true;
        }
        return null;
      });
      // item[indexData].make = `${item.make} & ${item.model}`;

      // item["make"] = `${item.make} & ${item.model}`;
      return null;
    });
    // setFilteredData(newData);
    return newData;
  };

  const orderDetails = [
    { label: "Start time", info: formatDateHour(asset?.scheduledStartTime) },
    { label: "End time", info: formatDateHour(asset?.scheduledEndTime) },
  ];

  const handleClick = async () => {
    setOpen(() => true);
  };

  const handleDialogConfirm = async (confirmed: boolean) => {
    if (confirmed) {
      try {
        await Order.deleteOrderLocation(asset._id);
        dispatch(showAlert("Deleted Order Successfully!", AlertEnum.success));
        window.location.reload();
      } catch (e) {
        dispatch(
          showAlert("Error when trying to Created Order", AlertEnum.error),
        );
      }
    }
    setOpen(() => false);
  };

  const updateStatusData = async (id: any) => {
    let showAssetOrder;
    let newData = [...filteredData];
    newData &&
      filterData(newData).map((item: any, index: any) => {
        if (item._id === id) {
          newData[index].showAssetOrder = !item.showAssetOrder;
          showAssetOrder = item.showAssetOrder;
        }
        return null;
      });
    setFilteredData(newData);
    dispatch(startLoaderDrawer());
    try {
      await FleetLocationServices.updateAssetsOrders(
        asset._id,
        id,
        showAssetOrder,
      );
      dispatch(stopLoaderDrawer());
    } catch (e) {
      dispatch(stopLoaderDrawer());
    }
  };

  const searchChecked = (id: any) => {
    let status;
    filterData &&
      filterData(filteredData).map((item: any) => {
        if (item._id === id) {
          status = item.showAssetOrder;
        }
        return null;
      });

    return status;
  };

  return (
    <>
      <div style={{ display: "flex", height: "50px", alignItems: "center" }}>
        <div
          className="text-cement text-l mt-10"
          style={{ margin: "20px 0 ", paddingRight: "10px" }}
        >
          ORDER DETAILS
        </div>
        <StatusLabel value={asset.status} />
      </div>
      <Paper elevation={4}>
        <Card className="my-4">
          <div className={classOrder.defaultCardHeader}>
            <div className="left">
              <span className="small">Order Placed</span>
            </div>
          </div>
          <TableContainer>
            <Table>
              <TableBody>
                {orderDetails.map(({ info, label }, i) => (
                  <TableRow key={i}>
                    <TableCell>{label}</TableCell>
                    <TableCell className={classOrder.aligmentRigth}>
                      {info}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <ConfirmDialog
            open={open}
            titleConfirmDialog="YOU'RE ABOUT TO CANCEL THIS ORDER"
            bodyConfirmDialog="Are you sure you want to cancel the order?"
            onClose={handleDialogConfirm}
          />
        </Card>
      </Paper>
      <div className="text-cement text-l mt-10" style={{ margin: "20px 0 " }}>
        Order history
      </div>
      <Paper elevation={4}>
        <Card className="my-4">
          <div className={classOrder.defaultCardHeader}>
            <div
              className="left"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <span className="small">ASSET ORDERS</span>
              <Button
                variant="contained"
                className={Styles.ButtonWhite}
                onClick={() => editAssets()}
              >
                EDIT ORDER ASSETS
              </Button>
            </div>
          </div>
          <TableContainer>
            <Table className={classes.table}>
              <TableHeader
                schema={schema}
                backgroundHeader="white"
                whithoutBackground={true}
                color="#000"
              />
              <TableBody>
                {filteredData &&
                  filterData(filteredData).map((item: any, i: any) => {
                    return (
                      <TableRow key={i} className={classes.tRowWhite}>
                        {schema.map(({ key, Component, componentProps }) => (
                          <TableCell key={key}>
                            {Component ? (
                              <Component
                                {...componentProps}
                                value={item[key]}
                                checked={() => searchChecked(item[key])}
                                id={item[key]}
                                updateStatusData={() =>
                                  updateStatusData(item[key])
                                }
                                showComponent={showEdit}
                              />
                            ) : (
                              item[key] ?? "-"
                            )}
                          </TableCell>
                        ))}
                        <TableCell>{""}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Paper>
      <Paper elevation={4}>
        <Card className="my-4">
          <div className={classOrder.defaultCardHeader}>
            <div className="left">
              <span className="small">NOTES</span>
            </div>
          </div>
          <Grid container className="p-5">
            <Grid item xs={12}>
              {asset.notes || "No notes submitted"}
            </Grid>
          </Grid>
        </Card>
      </Paper>
      {asset.status === "pending" && (
        <Button
          variant="contained"
          classes={{
            root: classOrder.buttonStyle,
          }}
          onClick={handleClick}
        >
          Cancel Order
        </Button>
      )}
    </>
  );
};

export default OrderDetails;
