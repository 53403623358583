import { format, isBefore } from "date-fns";
import { getFirstDayMonth, getLastDayMonth } from "helpers/dateHelpers";

export const GetFirstDayOfWeek = (date: Date) => {
  return format(
    new Date(date.setDate(date.getDate() - date.getDay())),
    "dd MMM yyyy",
  );
};

export const GetLastDayOfWeek = (date: Date) => {
  return format(
    new Date(date.setDate(date.getDate() + (6 - date.getDay()))),
    "dd MMM yyyy",
  );
};

export const GetDaysOf = (date: Date, x: "month" | "week") => {
  const days: {
    date: number;
    disable: any;
    month: any;
  }[][] = [];
  const firstDay =
    x === "month"
      ? new Date(getFirstDayMonth(date, "dd MMM yyyy"))
      : new Date(GetFirstDayOfWeek(date));
  const lastDay =
    x === "month"
      ? new Date(getLastDayMonth(date, "dd MMM yyyy"))
      : new Date(GetLastDayOfWeek(date));

  let week = 0;
  let disabled = false;
  days.push([]);
  while (firstDay <= lastDay) {
    if (new Date(firstDay).getDay() === 0) {
      days.push([]);
      week++;
    }
    days[week].push({
      date: new Date(firstDay).getDate(),
      month: new Date(firstDay).getMonth(),
      disable: disabled,
    });
    if (
      x === "month" &&
      new Date(
        firstDay.getFullYear(),
        firstDay.getMonth(),
        firstDay.getDate() + 1,
      ).getDate() < new Date(firstDay).getDate()
    ) {
      disabled = true;
    }
    firstDay.setDate(firstDay.getDate() + 1);
  }
  if (days[0][0] && days[0].length < 7) {
    const diff = 7 - days[0].length;
    const lastFirstDay = new Date(
      date.getFullYear(),
      days[0][0].month,
      days[0][0].date - 1,
    );
    for (let i = 0; i < diff; i++) {
      days[0].unshift({
        date: new Date(lastFirstDay).getDate(),
        month: new Date(lastFirstDay).getMonth(),
        disable: true,
      });
      lastFirstDay.setDate(lastFirstDay.getDate() - 1);
    }
  }
  if (days[days.length - 1][0] && days[days.length - 1].length < 7) {
    const diff = 7 - days[days.length - 1].length;
    const nextFirstDay = new Date(
      date.getFullYear(),
      days[days.length - 1][days[days.length - 1].length - 1].month,
      days[days.length - 1][days[days.length - 1].length - 1].date + 1,
    );
    for (let i = 0; i < diff; i++) {
      days[days.length - 1].push({
        date: new Date(nextFirstDay).getDate(),
        month: new Date(nextFirstDay).getMonth(),
        disable: true,
      });
      nextFirstDay.setDate(nextFirstDay.getDate() + 1);
    }
  }
  return days;
};
export const isBeforeToday = (date: Date) => {
  const today = new Date();
  today.setDate(today.getDate() - 2);
  today.setHours(23, 59, 59, 0);
  return isBefore(date, today);
};
export const workHours = () => {
  //create an array from "8:00AM" to "5:00PM"
  const workHours = [];
  for (let i = 0; i < 12; i++) {
    workHours.push(`${i}:00AM`);
  }
  for (let i = 1; i < 12; i++) {
    workHours.push(`${i}:00PM`);
  }
  return workHours;
};
