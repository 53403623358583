export const formatNumber = (num: any): string => {
  if (!num && num !== 0) return "";
  let rounded = Math.round(num * 100) / 100;
  return rounded.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const setStatusChange = (label: any) => {
  let labelUpper = label.toUpperCase();
  let labelStatus: any;
  switch (labelUpper) {
    case "CANCEL_ADMIN":
      labelStatus = "Cancelled by Admin";
      break;
    case "CANCEL_CONSUMER":
      labelStatus = "Cancelled by Customer";
      break;
    case "CANCEL_DRIVER":
      labelStatus = "Cancelled by Driver";
      break;
    case "INVOICED":
      labelStatus = "Order Invoiced";
      break;
    default:
      labelStatus = label.toUpperCase();
      break;
  }
  return labelStatus;
};

export const setColor = (value: string) => {
  let style = {} as any;
  let valueUpper = value.toUpperCase();
  switch (valueUpper) {
    case "PENDING":
      style = {
        background: "#737e94",
        color: "#e4eaf7",
      };
      break;
    case "PARTIALLY_SERVICED":
      style = {
        background: "#fa8e46",
        color: "#f9e0d0",
      };
      break;
    case "SERVICED":
      style = {
        background: "#6dab99",
        color: "#dcf9f0",
      };
      break;
    case "INVOICED":
      style = {
        background: "#6dab99",
        color: "#eafbf6",
      };
      break;
    case "PAID":
      style = {
        background: "#4b6299",
        color: "#c4cbdd",
      };
      break;
    case "CANCEL_ADMIN":
      style = {
        background: "#de0c0c",
        color: "white",
      };
      break;
    case "CANCEL_CUSTOMER":
      style = {
        background: "#de0c0c",
        color: "white",
      };
      break;
    case "CANCEL_CONSUMER":
      style = {
        background: "#de0c0c",
        color: "white",
      };
      break;
    case "ACTIVE":
      style = {
        background: "#de0c0c",
        color: "white",
      };
      break;
  }
  return style;
};
