import { makeStyles } from "@material-ui/core";

export const styles = makeStyles({
  ButtonWhite: {
    background: "#fff",
    color: "#000000",
    padding: ".5rem 1rem",
    float: "right",
  },
});
