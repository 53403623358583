export const colors = [
  {
    label: "Blue",
    value: "Blue",
    backgroundColor: "#20A6FD",
    textColor: "#FFF",
  },
  {
    label: "White",
    value: "White",
    backgroundColor: "#FFFFFF",
    textColor: "#737373",
  },
  {
    label: "Green",
    value: "Green",
    backgroundColor: "#7ED320",
    textColor: "#FFF",
  },
  { label: "Red", value: "Red", backgroundColor: "#D0011B", textColor: "#FFF" },
  {
    label: "Yellow",
    value: "Yellow",
    backgroundColor: "#F9E81E",
    textColor: "#737373",
  },
  {
    label: "Black",
    value: "Black",
    backgroundColor: "#000000",
    textColor: "#FFF",
  },
  {
    label: "Gray",
    value: "Gray",
    backgroundColor: "#9699A0",
    textColor: "#FFF",
  },
  {
    label: "Orange",
    value: "Orange",
    backgroundColor: "#FD8F02",
    textColor: "#FFF",
  },
  {
    label: "Brown",
    value: "Brown",
    backgroundColor: "#976C45",
    textColor: "#FFFFFF",
  },
  {
    label: "Purple",
    value: "Purple",
    backgroundColor: "#BB31C5",
    textColor: "#FFFFFF",
  },
];

export const fuelTypes = [
  { label: "Regular", value: 87 },
  { label: "REC90", value: 90 },
  { label: "Premium", value: 93 },
  { label: "Diesel", value: "ulsd" },
  { label: "Red Dye Diesel", value: "usldred" },
];

export const carTypes = [
  "SEDAN",
  "COUPE",
  "SPORTS CAR",
  "STATION WAGON",
  "HATCHBACK",
  "CONVERTIBLE",
  "SPORT-UTILITY VEHICLE (SUV)",
  "MINIVAN",
  "PICKUP TRUCK",
  "MUSCLE CAR",
];

export const subscriptionValues = [
  { value: "false", label: "Yes" },
  { value: "true", label: "No" },
];

export const statusValues = [
  { value: "true", label: "Active" },
  { value: "false", label: "Inactive" },
];
