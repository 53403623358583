import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles({
  aligmentCenter: {
    textAlign: "center",
  },
  aligmentRigth: {
    textAlign: "right",
  },
  defaultCardHeader: {
    background: "#BBB",
    color: "#000",
    width: "100%",
    display: "flex",
    fontWeight: "bold",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px",
    "& > div": {
      display: "flex",
      flexDirection: "column",
    },
    "& .right": {
      textAlign: "right",
    },
    "& .small": {
      fontSize: "12px",
    },
    "& .big": {
      fontSize: "16px",
      fontWeight: "600",
    },
  },
  buttonStyle: {
    backgroundColor: "var(--orange)",
    color: "white",
    height: 30,
    marginTop: 10,
    marginBottom: 20,
    marginLeft: 20,
  },
});
