export const GET_FLEET_CLIENT = "GET_FLEET_CLIENT";

export interface IFleetLocation {
  location: string;
  permissions: string[];
  _id: string;
}

export interface IGetFleetClient {
  type: typeof GET_FLEET_CLIENT;
  payload: IFleetClient;
}

export interface IGetFleetClient {
  type: typeof GET_FLEET_CLIENT;
  payload: IFleetClient;
}

export interface IFleetClient {
  username: string;
  firstName: string;
  lastName: string;
  dspCode: string;
  company: string;
  type: [string];
  fleetLocations: IFleetLocation[];
  _id: string;
  updatedAt: Date;
  createdAt: Date;
  fuelingDays: Number;
  assets: Number;
}

export type fleetClientDispatchTypes = IGetFleetClient;
