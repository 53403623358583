import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { UserRoles } from "../actions/authAction/authActionTypes";
import { PrivateRoutes, PublicRoutes } from "./interfaces";
import { useSelector, useDispatch } from "react-redux";
import SignupRoutes from "./SignupRoutes";
import Loader from "../components/SharedComponents/Loader";
import { RootStore } from "store";
import { getFleetClient } from "actions/fleetClientActions";
import { getAddresses } from "actions/addressesActions";
import Scheduler from "components/Scheduler/Scheduler";
import Sidebar from "components/Sidebar";

const PrivateRoute = lazy(() => import("../components/PrivateRoute"));
const Login = lazy(() => import("../pages/Login"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const Vehicles = lazy(() => import("../pages/Vehicles"));
const Orders = lazy(() => import("../pages/Orders"));
const RecurrentOrders = lazy(() => import("../pages/RecurrentOrders"));
const CreateAccount = lazy(() => import("../pages/CreateAccount"));
const Invoices = lazy(() => import("../pages/Invoices"));
const Help = lazy(() => import("../pages/Help"));

const Routes = () => {
  const { user, accessToken } = useSelector((state: RootStore) => state.auth);
  const { locationAddresses } = useSelector((state: RootStore) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    if (accessToken && user?.id) {
      dispatch(getFleetClient(user.id));
    }
  }, [user, accessToken, dispatch]);

  useEffect(() => {
    // infinite loop !!! ->
    if (user && !locationAddresses.length) {
      dispatch(getAddresses(user!.id));
    }
  }, [locationAddresses, user, dispatch]);

  return (
    <Router>
      <Switch>
        <Suspense fallback={<Loader />}>
          <PrivateRoute
            component={DefaultContainer}
            path={""}
            requiredRole={[UserRoles.client, UserRoles.admin]}
          />
          <Route exact path={PublicRoutes.login} component={Login} />
          <Route
            exact
            path={PublicRoutes.createAccount}
            component={CreateAccount}
          />
          <SignupRoutes />
        </Suspense>
      </Switch>
    </Router>
  );
};

const DefaultContainer = () => {
  return (
    <Sidebar>
      <PrivateRoute
        exact
        path={PrivateRoutes.home}
        requiredRole={[UserRoles.client, UserRoles.admin]}
        component={Dashboard}
      />
      <PrivateRoute
        exact
        path="/dashboard"
        requiredRole={[UserRoles.client, UserRoles.admin]}
        component={Dashboard}
      />
      <PrivateRoute
        exact
        path="/scheduler"
        requiredRole={[UserRoles.client, UserRoles.admin]}
        component={Scheduler}
      />
      <PrivateRoute
        exact
        path="/vehicles"
        requiredRole={[UserRoles.client, UserRoles.admin]}
        component={Vehicles}
      />

      <PrivateRoute
        exact
        path="/orders"
        requiredRole={[UserRoles.client, UserRoles.admin]}
        component={Orders}
      />

      <PrivateRoute
        exact
        path="/recurrent-orders"
        requiredRole={[UserRoles.client, UserRoles.admin]}
        component={RecurrentOrders}
      />

      <PrivateRoute
        exact
        path="/invoices"
        requiredRole={[UserRoles.client, UserRoles.admin]}
        component={Invoices}
      />
      <PrivateRoute
        exact
        path="/help"
        requiredRole={[UserRoles.client, UserRoles.admin]}
        component={Help}
      />
    </Sidebar>
  );
};

export default Routes;
