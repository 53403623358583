import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100vw",
      height: "100vh",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
      "& > *": {
        width: "50%",
        height: "60%",
        borderRadius: 20,
        padding: 20,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
      },
    },
    headingStyles: {
      color: "#4a5568",
      display: "flex",
      alignItems: "center",
      marginBottom: 50,
      "& > *": {
        marginRight: "10px",
      },
    },
    formStyles: {
      width: "70%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    buttonStyle: {
      backgroundColor: "var(--orange)",
      color: "white",
      height: 50,
      marginTop: 50,
      marginBottom: 20,
    },
    fieldStyles: {
      marginBottom: 20,
    },
    closeStyles: {
      color: "#0e6ba8",
      marginLeft: 10,
      cursor: "pointer",
    },
  }),
);
