import { post } from "../api";
import { IUserApiResponse } from "../actions/authAction/authActionTypes";
export class AuthServices {
  static async login(
    email: string,
    password: string,
  ): Promise<IUserApiResponse> {
    const { data } = await post<IUserApiResponse>("fleetclients/login", {
      data: { email, password },
    });
    return data;
  }

  static async updatePassword(
    id: string,
    password: string,
  ): Promise<{ message: string }> {
    try {
      const { data } = await post<{ message: string }>(
        "fleetclients/updatePassword",
        {
          data: { id, password },
        },
      );
      return data;
    } catch (err) {
      return {
        message: "Something went wrong while updating the password. Try again.",
      };
    }
  }

  static logout() {
    localStorage.clear();
  }
}
