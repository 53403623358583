export enum PrivateRoutes {
  home = "/",
  adminHome = "/admin",
}

export enum PublicRoutes {
  login = "/login",
  signup = "/signup",
  signupCompany = "/signup/company",
  signupLocation = "/signup/location",
  createAccount = "/createAccount/:id",
}
