import { AddressDispatchTypes } from "actions/addressesActions/addressesActionTypes";
import { LoaderDispatchTypes } from "actions/loaderActions/loaderActionTypes";
import { AlertDispatchTypes } from "actions/alertActions/alertActionTypes";
import { SignupDispatchTypes } from "actions/signupAction/signupActionTypes";

export const LOGGING_IN = "LOGGING_IN";
export const LOGIN_SUCEEDED = "LOGIN_SUCEEDED";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";

export interface LoggingIn {
  type: typeof LOGGING_IN;
}

export interface LoginFail {
  type: typeof LOGIN_FAILED;
}

export interface LoginSuceeded {
  type: typeof LOGIN_SUCEEDED;
  payload: {
    accessToken: string;
    decodedUser: IUserInfo;
  };
}

export interface Logout {
  type: typeof LOGOUT;
}
export interface IToken {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token?: string;
  id_token: string;
  id: string;
}

enum LocationPermissions {
  scheduled = "scheduled",
  vehicles = "vehicles",
}
interface FleetLocation {
  location: string;
  permissions: LocationPermissions[];
}

enum ClientTypes {
  owner = "owner",
  subaccount = "subaccount",
}
export interface IFleetClient {
  _id: string;
  company: string;
  companyId: string;
  firstName: string;
  lastName: string;
  username: string;
  fleetLocations: FleetLocation[];
  type: ClientTypes[];
}

export type IUserApiResponse = {
  token: IToken;
  fleetClient: IFleetClient;
};

export type IUser = {
  email: string;
  family_name: string;
  given_name: string;
  id: string;
  name: string;
  roles: [UserRoles.admin, UserRoles.client];
};

export type IUserInfo = {
  aud: string;
  exp: number;
  iat: number;
  iss: string;
  sub: IUser;
};

export enum UserRoles {
  admin = "admin",
  client = "fleetclient",
}

export type LoginDispatchTypes =
  | LoggingIn
  | LoginFail
  | LoginSuceeded
  | Logout
  | LoaderDispatchTypes
  | AlertDispatchTypes
  | AddressDispatchTypes
  | SignupDispatchTypes;
