import {
  IFleetClient,
  GET_FLEET_CLIENT,
  fleetClientDispatchTypes,
} from "actions/fleetClientActions/fleetClientActionTypes";

interface IDefaultState {
  client: IFleetClient | null;
}

const defaultState: IDefaultState = {
  client: null,
};

const fleetClientReducer = (
  state = defaultState,
  action: fleetClientDispatchTypes,
): typeof defaultState => {
  switch (action.type) {
    case GET_FLEET_CLIENT:
      return {
        ...defaultState,
        client: action.payload,
      };
    default:
      return state;
  }
};

export default fleetClientReducer;
