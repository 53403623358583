const defaultState: any = {
  type: "",
  showIcon: false,
};

const pointFirebase = (state: any = defaultState, action: any): any => {
  switch (action.type) {
    case "UPDATE_POINT_REDUCER":
      return {
        ...defaultState,
        type: action.payload.type,
        showIcon: action.payload.showIcon,
      };
    default:
      return state;
  }
};

export default pointFirebase;
