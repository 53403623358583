import React, {
  FunctionComponent,
  useState,
  useEffect,
  SyntheticEvent,
} from "react";
import { useSelector } from "react-redux";
import { Button, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { RootStore } from "store";
import { AlertProps } from "./interface";

const Alert: FunctionComponent<AlertProps> = ({ isButtonClose = false }) => {
  const { severity, show, message } = useSelector(
    (state: RootStore) => state.alert,
  );
  const [open, setOpen] = useState(show);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const handleClose = (event: SyntheticEvent | MouseEvent, reason?: string) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={open}
      onClose={handleClose}
    >
      <MuiAlert elevation={6} variant="filled" severity={severity}>
        {message}
        {isButtonClose && (
          <Button size="small" onClick={handleClose}>
            x
          </Button>
        )}
      </MuiAlert>
    </Snackbar>
  );
};

export default Alert;
