import { Dispatch } from "redux";
import {
  GET_FLEET_CLIENT,
  IGetFleetClient,
  IFleetClient,
} from "./fleetClientActionTypes";
import { FleetClient } from "services/FleetClient";

export const getFleetClient = (clientId: string) => async (
  dispatch: Dispatch<IGetFleetClient>,
) => {
  try {
    const fleetClient = await FleetClient.getClient(clientId);
    dispatch({
      type: GET_FLEET_CLIENT,
      payload: fleetClient,
    });
  } catch (e) {}
};

export const updateFleetClient = (
  clientId: string,
  newClient: IFleetClient,
) => async (dispatch: any) => {
  try {
    await FleetClient.updateClient(clientId, newClient);
    dispatch(getFleetClient(clientId));
  } catch (e) {}
};
