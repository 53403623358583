import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { ConfirmDialogProps } from "./interfaces";

const ConfirmDialog = ({
  open = false,
  onClose,
  bodyConfirmDialog,
  titleConfirmDialog = "Confirmation!",
}: ConfirmDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="ConfirmDialog"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle>{titleConfirmDialog}</DialogTitle>
      <DialogContent>
        <p>{bodyConfirmDialog}</p>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClose(true)}
          color="primary"
          variant="contained"
        >
          Confirm
        </Button>
        <Button onClick={() => onClose(false)} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
