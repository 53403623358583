import { AlertDispatchTypes } from "../alertActions/alertActionTypes";

export const SET_ADDRESSES = "SET_ADDRESSES";
export const CLEAR_ADDRESSES = "CLEAR_ADDRESSES";

export interface setAddresses {
  type: typeof SET_ADDRESSES;
  payload: LocationAddress[];
}

export interface clearAddresses {
  type: typeof CLEAR_ADDRESSES;
}

export interface ICoordinates {
  lat: number;
  lng: number;
}

export interface LocationAddress {
  locationId: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  coordinates: ICoordinates | null;
}

export type AddressDispatchTypes =
  | setAddresses
  | clearAddresses
  | AlertDispatchTypes;
