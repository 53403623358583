import React from "react";
import Modal from "@material-ui/core/Modal";

import PasswordReset from "../PasswordReset";
import { PasswordResetModalProps } from "./interfaces";

const PasswordResetModal = ({ open, closeModal }: PasswordResetModalProps) => {
  return (
    <Modal open={open}>
      <>
        <PasswordReset closeModal={closeModal} />
      </>
    </Modal>
  );
};

export default PasswordResetModal;
