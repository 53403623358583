import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootStore } from "store";

import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropdown from "@material-ui/icons/ArrowDropDown";
import ExitToApp from "@material-ui/icons/ExitToApp";
import LockOpen from "@material-ui/icons/LockOpen";
import PersonIcon from "@mui/icons-material/Person";

import { logoutAction } from "../../../actions/authAction";

import { useStyles } from "./styles";

import PasswordResetModal from "../PasswordResetModal";
import AccountModal from "../AccountModal";

const UserDropdown = () => {
  const { user } = useSelector((state: RootStore) => state.auth);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAccount, setIsOpenAccount] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePasswordModal = () => {
    handleClose();
    setIsOpen(!isOpen);
  };

  const handlePasswordModalAccount = () => {
    handleClose();
    setIsOpenAccount(!isOpenAccount);
  };

  const handleLogout = () => {
    handleClose();
    dispatch(logoutAction());
  };

  const composeUserName = (firstName: string, familyName: string): string => {
    return `${firstName} ${familyName}`;
  };

  const emailStr = (email: string): string => {
    return `${email}`;
  };

  return (
    <>
      <div className="flex justify-center pb-2 w-90 mt-5 text-sm rounded-md border-solid border-black">
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          classes={{ label: classes.buttonLabel, root: classes.menuStyle }}
        >
          <span className="border-radius-0">
            {user && composeUserName(user.given_name, user.family_name)}
          </span>
          <ArrowDropdown className={classes.dropDownArrowStyles} />
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={handlePasswordModalAccount}
            className={classes.menuItemStyle}
          >
            <PersonIcon className={classes.iconStyles} />
            Personal Info
          </MenuItem>
          <MenuItem
            onClick={handlePasswordModal}
            className={classes.menuItemStyle}
          >
            <LockOpen className={classes.iconStyles} />
            Password reset
          </MenuItem>
          <MenuItem onClick={handleLogout} className={classes.menuItemStyle}>
            <ExitToApp className={classes.iconStyles} />
            Logout
          </MenuItem>
        </Menu>
      </div>
      <span className="flex justify-center text-sm mb-5 text-gray-600">
        {user && emailStr(user.email)}
      </span>
      <PasswordResetModal open={isOpen} closeModal={() => setIsOpen(false)} />
      <AccountModal
        open={isOpenAccount}
        closeModal={() => setIsOpenAccount(false)}
      />
    </>
  );
};

export default UserDropdown;
