import {
  LOADING_DRAWER,
  LOADED_DRAWER,
} from "../actions/loaderActions/loaderActionTypes";

type IDefaultState = boolean;
const defaultState: IDefaultState = false;

const loaderDrawerReducer = (
  state: IDefaultState = defaultState,
  action: any,
): IDefaultState => {
  switch (action.type) {
    case LOADED_DRAWER:
      return false;
    case LOADING_DRAWER:
      return true;
    default:
      return state;
  }
};

export default loaderDrawerReducer;
