import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  layout: {
    display: "grid",
    gridTemplateColumns: "70px 1fr",
    "@media (min-width: 640px)": {
      gridTemplateColumns: "250px 1fr",
    },
  },
});
