import { AssetOrder } from "../actions/ordersActions/orderActiontypes";
import { IFleetClient } from "../actions/fleetClientActions/fleetClientActionTypes";
import { post, get, put } from "../api";

interface DateRange {
  start: Date;
  end?: Date;
}

export interface IFleetLocationOrder {
  id: string;
  location: { _id: string; name: string };
  fleetClientOrder: string;
  createdAt: Date;
  updatedAt: Date;
  assetOrders: AssetOrder[];
  scheduledStartTime: Date;
  scheduledEndTime: Date;
}
export class FleetClient {
  static async getAssetOrders(locationId: string, range: DateRange) {
    const { data: assetsOrder } = await post<AssetOrder[]>(
      `/fleetportal/fleet-location-orders/${locationId}`,
      { data: range },
    );
    return assetsOrder;
  }

  static async getClient(clientId: string) {
    const { data } = await get<IFleetClient>(`fleetclients/${clientId}`, {});

    return data;
  }

  static async updateClient(clientId: string, updatedClient: IFleetClient) {
    const { data } = await put<IFleetClient>(`fleetclients/${clientId}`, {
      data: updatedClient,
    });

    return data;
  }

  static async getLatestClientOrders() {
    const { data } = await get<IFleetLocationOrder>(
      "/fleetportal/fleet-location-order/latest",
    );

    return data;
  }

  static async addCardFleet(userId: string, paymentMethod: any, email: string) {
    const data = await post<any>(`/fleetportal/stripe`, {
      data: {
        userId,
        paymentMethod,
        email,
      },
    });
    return data;
  }

  static async validateIfHaveCard(userId: string) {
    const { data } = await get<any>(`/fleetportal/data-stripe/${userId}`, {});
    return data;
  }

  static async deleteCard(userId: string, paymentMethodId: any) {
    const data = await post<any>(`/fleetportal/delete-data-stripe`, {
      data: {
        userId,
        paymentMethodId,
      },
    });
    return data;
  }

  static async getLocationOrderPaid(locationId: string) {
    const { data } = await get<any>(
      `/fleetportal/get-location-order/${locationId}`,
      {},
    );
    return data;
  }

  static async updateToken(uid: any, token: string) {
    const { data } = await post<any>(`/client/${uid}/updateToken`, {
      data: {
        token,
      },
    });
    return data;
  }

  static async updateOpsData(dataOpsData: any) {
    const { data } = await post<any>(`/fleetclients/updateOpsData`, {
      data: {
        fleetClientId: dataOpsData.fleetClientId,
        numVehicles: dataOpsData.numVehicles,
        productivity: dataOpsData.productivity,
        payroll: dataOpsData.payroll,
        operatingCosts: dataOpsData.operatingCosts,
      },
    });
    return data;
  }
}
