export const LOADING = "LOADING";
export const LOADED = "LOADED";
export const LOADING_DRAWER = "LOADING_DRAWER";
export const LOADED_DRAWER = "LOADED_DRAWER";

export interface Loading {
  type: typeof LOADING;
}

export interface Loaded {
  type: typeof LOADED;
}

export type LoaderDispatchTypes = Loading | Loaded;
