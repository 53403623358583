export const updatePointReducer = (type: any, value: any) => async (
  dispatch: any,
) => {
  dispatch({
    type: "UPDATE_POINT_REDUCER",
    payload: {
      type,
      value,
    },
  });
};
