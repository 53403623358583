import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  menuStyle: {
    width: "100%",
    backgroundColor: "#e1e2e4",
    borderRadius: "0px",
  },
  menuItemStyle: {
    color: "#000066",
    justifyContent: "flex-start",
  },
  iconStyles: {
    marginRight: 10,
    width: 20,
  },
  dropDownArrowStyles: {
    width: 15,
    height: 15,
    marginRight: 6,
    borderRadius: 5,
    color: "white",
    background: "#000066",
  },
  buttonLabel: {
    textTransform: "uppercase",
    fontSize: "16px",
    justifyContent: "space-between",
    color: "#000000",
  },
});
