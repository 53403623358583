import { DataSchema } from "components/Table/interfaces";
import { StatusLabel } from "../../statusLabel";
import { CheckboxComponent } from "../../Checkbox";

export const schema: DataSchema[] = [
  { header: "TAG", key: "tag" },
  { header: "MAKE & MODEL", key: "make" },
  { header: "FUEL", key: "fuel" },
  { header: "STATUS", key: "status", Component: StatusLabel },
  { header: "", key: "_id", Component: CheckboxComponent },
];
