import {
  IUser,
  IFleetClient,
  LoginDispatchTypes,
  LOGGING_IN,
  LOGIN_SUCEEDED,
  LOGIN_FAILED,
  LOGOUT,
} from "../actions/authAction/authActionTypes";

export interface IDefaultState {
  accessToken: string | null;
  user: IUser | null;
  fleetClient: IFleetClient | null;
}

const defaultState: IDefaultState = {
  accessToken: null,
  user: null,
  fleetClient: null,
};

const authReducer = (
  state: IDefaultState = defaultState,
  action: LoginDispatchTypes,
): IDefaultState => {
  switch (action.type) {
    case LOGGING_IN:
      return {
        ...state,
      };
    case LOGIN_FAILED:
      return {
        ...state,
      };
    case LOGIN_SUCEEDED:
      const { accessToken, fleetClient, decodedUser } = action.payload;
      const user = decodedUser.sub;
      return {
        accessToken,
        user,
        fleetClient,
      };
    case LOGOUT:
      return {
        accessToken: null,
        user: null,
        fleetClient: null,
      };
    default:
      return state;
  }
};

export default authReducer;
