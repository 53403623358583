import React from "react";
import { setStatusChange, setColor } from "utils/utils";

export const StatusLabel = (prop: any) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          padding: "4px",
          textAlign: "center",
          alignItems: "center",
          paddingTop: "2px",
          borderRadius: "8px",
          paddingBottom: "2px",
          justifyContent: "center",
          border: `2px solid ${setColor(prop.value).background}`,
          backgroundColor: `${setColor(prop.value).background}`,
          color: `${setColor(prop.value).color}`,
        }}
      >
        {setStatusChange(prop.value)}
      </div>
    </>
  );
};
