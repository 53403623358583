import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch } from "react-redux";
import { LocationAddress } from "actions/addressesActions/addressesActionTypes";
import { LocationsPickerProps } from "./interfaces";
import { useAlert } from "hooks/useAlert";
import { useStyles } from "./styles";
import { RootStore } from "store";
import { setSelectedLocation } from "actions/locationActions";

const LocationsPicker = ({
  addresses,
  setChosenAddress,
}: LocationsPickerProps) => {
  const { locations, fleetClient } = useSelector((state: RootStore) => state);
  const { selectedLocation } = locations;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(
    selectedLocation ?? "",
  );

  const alert = useAlert();
  const classes = useStyles();

  useEffect(() => {
    dispatch(setSelectedLocation(selectedLocation ?? ""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation]);

  // useEffect(() => {
  //   if (fleetClient) {
  //     if(addresses) {
  //       if (selectedAddress === "") {
  //         setChosenAddress(addresses[0].locationId);
  //         setSelectedAddress(addresses[0].locationId);
  //         dispatch(setSelectedLocation(addresses[0].locationId));
  //       }
  //     }
  //   }
  // }, [fleetClient,addresses,selectedAddress]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const locationId = event.target.value as string;
    const [address] = addresses.filter(
      (location: LocationAddress) => locationId === location.locationId,
    );
    dispatch(setSelectedLocation(address.locationId));
    alert(`Successfully selected location at: ${address.address}`);
  };

  return (
    <div className={classes.selectStyles}>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          classes={{ root: classes.inputStyles }}
          value={selectedLocation ? selectedLocation : ""}
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          onChange={handleChange}
        >
          {addresses &&
            addresses.map(({ locationId, address }, i: number) => (
              <MenuItem key={i} value={locationId}>
                {address}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default LocationsPicker;
