import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "components/App/App";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { store, persistor } from "store";
import "../firebase";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ff5100",
    },
    secondary: {
      main: "#000066",
    },
  },

  typography: {
    fontFamily: ["Lato", "sans-serif", "Open Sans"].join(","),
  },
});

const AppIndex = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};
export default AppIndex;
