import { Dispatch } from "redux";
import {
  LOADING,
  LOADED,
  LoaderDispatchTypes,
  LOADING_DRAWER,
  LOADED_DRAWER,
} from "./loaderActionTypes";

export const startLoader = () => async (
  dispatch: Dispatch<LoaderDispatchTypes>,
) => {
  dispatch({ type: LOADING });
};

export const stopLoader = () => async (
  dispatch: Dispatch<LoaderDispatchTypes>,
) => {
  dispatch({ type: LOADED });
};

export const startLoaderDrawer = () => async (dispatch: Dispatch<any>) => {
  dispatch({ type: LOADING_DRAWER });
};

export const stopLoaderDrawer = () => async (dispatch: Dispatch<any>) => {
  dispatch({ type: LOADED_DRAWER });
};
