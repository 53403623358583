import {
  ALERT_ERROR,
  ALERT_WARNING,
  ALERT_SUCCESS,
  ALERT_INFO,
  ALERT_CLEAR,
  AlertDispatchTypes,
  AlertEnum,
} from "../actions/alertActions/alertActionTypes";

interface IDefaultState {
  message: string;
  show: boolean;
  severity?: AlertEnum;
}
const defaultState: IDefaultState = {
  message: "",
  show: false,
  severity: undefined,
};

const alertreducer = (
  state: IDefaultState = defaultState,
  action: AlertDispatchTypes,
): IDefaultState => {
  switch (action.type) {
    case ALERT_SUCCESS:
      return {
        ...action.payload,
        severity: AlertEnum.success,
        show: true,
      };
    case ALERT_ERROR:
      return {
        ...action.payload,
        severity: AlertEnum.error,
        show: true,
      };
    case ALERT_WARNING:
      return {
        ...action.payload,
        severity: AlertEnum.warning,
        show: true,
      };
    case ALERT_INFO:
      return {
        ...action.payload,
        severity: AlertEnum.info,
        show: true,
      };
    case ALERT_CLEAR:
      return {
        ...defaultState,
        severity: state.severity,
      };
    default:
      return state;
  }
};

export default alertreducer;
