import * as yup from "yup";

export const passwordResetValidation = yup.object().shape({
  password: yup
    .string()
    .required("This field is required")
    .matches(/.{8,}/gi, "Password must have at least 8 characters")
    .matches(
      /(?=.*[a-z])/gi,
      "Password must have at least 1 lowercase character",
    )
    .matches(
      /(?=.*[A-Z])/gi,
      "Password must have at least 1 uppercase character",
    )
    .matches(/(?=.*[0-9])/gi, "Password must have at least 1 numeric character")
    .matches(
      /(?=.*[!@#$%^&*])/gi,
      "Password must have at least 1 special character",
    ),
  repeatedPassword: yup
    .string()
    .required("This field is required")
    .matches(/.{4,}/gi, "Password must have at least 4 characters")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});
