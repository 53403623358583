import { AssetOrder } from "actions/ordersActions/orderActiontypes";
import { get, put, del, post } from "../api";

export class Order {
  static async getLocationOrders(locationId: string) {
    const { data } = await get(`/fleet-location-order/${locationId}`);

    return data;
  }

  static async getClientOrders(
    startDate: Date,
    endDate?: Date,
    location?: string,
    sortType?: any,
    page?: any,
    pageSize?: any,
  ) {
    const { data } = await get<any[]>(`/fleetportal/fleet-location-orders`, {
      params: {
        startDate: startDate.toISOString(),
        endDate: endDate ? endDate.toISOString() : null,
        location,
        sortType,
        page,
        pageSize,
      },
    });
    return data;
  }

  static async updateOrder(newOrder: AssetOrder) {
    const { data } = await put(`fleet-client-order/${newOrder._id}`, {
      data: newOrder,
    });

    return data;
  }
  static async deleteOrder(id: string) {
    const { data } = await del(`fleet-location-order/${id}/consumer`, {});

    return data;
  }

  static async deleteOrderLocation(id: string) {
    const { data } = await del(`fleet-location-order/${id}/consumer`, {});

    return data;
  }

  static async postLocationOrder(accessToken: string, dataLocation: any) {
    const { data } = await post(`fleet-location-order`, {
      data: {
        location: dataLocation.locationId,
        notes: dataLocation.notes,
        scheduledEndTime: dataLocation.dateEnd,
        scheduledStartTime: dataLocation.dateStart,
        assets: dataLocation.assets,
      },
    });

    return data;
  }

  static async postRecurrentOrder(dataRecurrentOrder: any) {
    const { data } = await post(`recurrent-fleet-location-order`, {
      data: dataRecurrentOrder,
    });

    return data;
  }

  static async getClientRecurrentOrders(locationId: any) {
    const { data } = await get<any[]>(
      `/recurrent-fleet-location-order?locationId=${locationId}`,
      {
        data: {
          locationId: locationId,
        },
      },
    );
    return data;
  }

  static async deleteClientRecurrentOrders(id: string) {
    const { data } = await del<any[]>(
      `/recurrent-fleet-location-order/${id}`,
      {},
    );
    return data;
  }
}
