import React, { useEffect } from "react";
import {
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { LinearProgress } from "@material-ui/core";
import { useStyles } from "./schedular.styles";
import { workHours } from "./utils";
import { makeStyles } from "@material-ui/styles";
import { format } from "date-fns";
import { setStatusChange } from "utils/utils";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import { useOnScreen } from "./hook";

const useStylesDay = makeStyles({
  root: {
    width: "calc(100% / 7)",
  },
});

const View: React.FC<any> = ({
  view,
  weeks,
  orders,
  activeDate,
  onDayClick = () => {},
  setShowIcon,
  showIcon,
  firstLoad,
  setFirstLoad,
  setDataShow,
  dataShow,
  ordersShow,
}) => {
  const classes = useStyles();
  const [selectedDay, setSelectedDay] = React.useState(new Date().getDate());
  const classesDay = useStylesDay();

  let isVisible = useOnScreen(ordersShow);

  useEffect(() => {
    if (isVisible.length > 0) {
      isVisible.map((item: any) => {
        if (item === false) {
          setShowIcon(true);
          return false;
        } else {
          setShowIcon(false);
          return false;
        }
      });
      let count = 0;
      isVisible.map((item: any) => {
        if (item === false) {
          count++;
        }
        return null;
      });
      if (count !== 0) {
        if (count === ordersShow.length) {
          bottomView(ordersShow[0]._id);
        }
      }
    } else {
      setShowIcon(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, ordersShow]);

  const onClickDay = (day: any, data: any) => {
    onDayClick(data);
    setSelectedDay(() => day.date);
  };

  const getClassStatus = (status: string) => {
    return (classes as any)[status];
  };

  const Event = ({
    schedule,
    status,
    totalAssets,
    details,
    setCountStatus,
    item,
  }: any) => {
    return (
      <div
        className={`${getClassStatus(status)} ${
          classes.itemCard
        } py-2 px-2 rounded-md h-full`}
        style={{ cursor: "pointer" }}
        id={item._id}
      >
        <div className="text-white text-lg">{schedule}</div>
        <div className="text-sm capitalize">{`${setStatusChange(
          status,
        )} (${totalAssets} Assets)`}</div>
        {status === "Scheduled" ? (
          <></>
        ) : (
          <>
            <LinearProgress
              className={classes.progress}
              variant="determinate"
              value={setCountStatus}
            />
            {item.detailsPaid.map((item: any) => {
              return (
                <>
                  <div className="text-white mt-2">{`${item.type} GAL - ${item.price}$`}</div>
                </>
              );
            })}
          </>
        )}
        <div>Notes: {item.notes}</div>
      </div>
    );
  };

  const EventWeek = ({
    schedule,
    status,
    totalAssets,
    details,
    setCountStatus,
    item,
  }: any) => {
    return (
      <div
        className={`${getClassStatus(status)} ${
          classes.itemCard
        } py-2 px-2 rounded-md h-full`}
        style={{
          cursor: "pointer",
          display: "flex",
          justifyContent: "space-between",
        }}
        id={item._id}
      >
        <div
          className="text-sm capitalize"
          style={{ color: "white" }}
        >{`${setStatusChange(status)}`}</div>
        <ArrowRight style={{ color: "white" }} />
      </div>
    );
  };

  const getDatesFromWeek = () => {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(
        activeDate.getFullYear(),
        weeks?.[1]?.[i]?.month,
        weeks?.[1]?.[i]?.date,
      );
      date.setDate(date.getDate());
      // dates.push(format(date, "EE (MMM dd)"));
      dates.push({
        day: format(date, "EE"),
        week: format(date, "dd"),
      });
    }
    return dates;
  };
  const liesInTime = (schedule: any, currTime: any) => {
    currTime = currTime.toLowerCase();
    schedule = schedule.toLowerCase().split("-");
    let startTime: any = schedule[0];
    let endTime: any = schedule[1];

    if (startTime.includes("am")) {
      startTime = Number(startTime.split("am")[0].split(":")[0]);
    } else {
      startTime = Number(startTime.split("pm")[0].split(":")[0]);
      startTime = startTime === 12 ? startTime : startTime + 12;
    }
    if (endTime.includes("am")) {
      endTime = Number(endTime.split("am")[0].split(":")[0]);
    } else {
      endTime = Number(endTime.split("pm")[0].split(":")[0]);
      endTime = endTime === 12 ? endTime : endTime + 12;
    }

    if (currTime.includes("am")) {
      currTime = Number(currTime.split("am")[0].split(":")[0]);
    } else {
      currTime = Number(currTime.split("pm")[0].split(":")[0]);
      currTime = currTime === 12 ? currTime : currTime + 12;
    }
    return currTime >= startTime && currTime <= endTime;
  };

  const setActivedDate = (day: any) => {
    if (
      day.date === new Date().getDate() &&
      day.month === new Date().getMonth()
    ) {
      return "selected-day";
    } else {
      return "";
    }
  };

  const bottomView = (id: any) => {
    var elmnt: any = document.getElementById(id);
    if (elmnt) {
      elmnt.scrollIntoView();
    }
  };

  if (view === "week") {
    return (
      <div id="content">
        {showIcon && (
          <button
            onClick={() => bottomView("options-holder")}
            style={{ position: "absolute", bottom: "0", left: "53%" }}
          >
            <ArrowDropDown style={{ fontSize: "60px", color: "#22c31d" }} />
          </button>
        )}
        <TableContainer>
          <Table className={classes.root}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {getDatesFromWeek().map((d) => {
                  return (
                    <TableCell>
                      <div>
                        <div style={{ fontSize: "20px" }}>{d.week}</div>
                        <div>{d.day.toUpperCase()}</div>
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {workHours().map((hour: any, index: number) => {
                return (
                  <TableRow>
                    <TableCell>{hour}</TableCell>
                    {weeks?.[1]?.map((day: any) => {
                      const fullDate = `${activeDate.getFullYear()}-${
                        day.month
                      }-${day.date}`;
                      const _ = orders[fullDate];
                      return (
                        <TableCell
                          onClick={() => onClickDay(day.date, _)}
                          className={`${
                            selectedDay === day.date && !day.disable
                              ? "selected-day"
                              : ""
                          } ${
                            classesDay.root
                          } border-solid border border-light-gray-500`}
                        >
                          {/* {_?.status === "pending" ? (
                          !isBeforeToday(new Date(fullDate)) && (
                            <div className="skip-service">
                              <p>SKIP</p>
                            </div>
                          )
                        ) : (
                          <span></span>
                        )} */}

                          <div className="day-content2">
                            {!_?.disabled &&
                              _ &&
                              liesInTime(_.schedule, hour) &&
                              EventWeek({
                                totalAssets: _.totalAssets,
                                details: _.details,
                                schedule: _.schedule,
                                status: _.status ?? ` -- `,
                                setCountStatus: _.setCountStatus,
                                item: _,
                              })}
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <div id="options-holder"></div>
      </div>
    );
  }

  return (
    <div id="content">
      {showIcon && (
        <button
          onClick={() => bottomView("options-holder")}
          style={{ position: "absolute", bottom: "0", left: "53%" }}
        >
          <ArrowDropDown style={{ fontSize: "60px", color: "#22c31d" }} />
        </button>
      )}
      <TableContainer>
        <Table className={classes.root}>
          <TableHead>
            <TableRow>
              <TableCell>SUN</TableCell>
              <TableCell>MON</TableCell>
              <TableCell>TUE</TableCell>
              <TableCell>WED</TableCell>
              <TableCell>THUR</TableCell>
              <TableCell>FRI</TableCell>
              <TableCell>SAT</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {weeks &&
              weeks.map((week: any, index: number) => {
                return (
                  <TableRow key={index}>
                    {week.map((day: any) => {
                      const fullDate = `${activeDate.getFullYear()}-${
                        day.month
                      }-${day.date}`;
                      const _ = orders[fullDate];
                      return (
                        <TableCell
                          onClick={() => onClickDay(day.date, _)}
                          className={`${setActivedDate(day)} ${
                            classesDay.root
                          } border-solid border border-light-gray-500`}
                        >
                          <div
                            className={`day-number ${
                              day.disable ? "text-gray-400" : ""
                            }`}
                          >
                            {day.date}
                          </div>
                          {/* {_?.status === "pending" ? (
                        !isBeforeToday(new Date(fullDate)) && (
                          <div className="skip-service">
                            <p>SKIP</p>
                          </div>
                        )
                      ) : (
                        <span></span>
                      )} */}
                          <div className="day-content">
                            {!_?.disabled &&
                              _ &&
                              Event({
                                totalAssets: _.totalAssets,
                                details: _.details,
                                schedule: _.schedule,
                                status: _.status ?? ` -- `,
                                setCountStatus: _.setCountStatus,
                                item: _,
                              })}
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <div id="options-holder"></div>
    </div>
  );
};
export default View;
