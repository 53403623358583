import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      width: "93%",
      minWidth: 13,
      color: "white",
      backgroundColor: "#ff5100",
      borderRadius: "6px",
      border: "1px solid #FF5100",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    inputStyles: {
      color: "white",
    },
    selectStyles: {
      borderRadius: 10,
      overflow: "hidden",
    },
  }),
);
