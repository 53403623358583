import React from "react";
import Checkbox from "@material-ui/core/Checkbox";

export const CheckboxComponent = (prop: any) => {
  return (
    <>
      {prop.showComponent && (
        <Checkbox
          edge="start"
          checked={prop.checked(prop.id)}
          // tabIndex={index}
          // disableRipple
          onChange={() => prop.updateStatusData(prop.id)}
          color="primary"
        />
      )}
    </>
  );
};
