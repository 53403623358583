import { Dispatch } from "redux";
import {
  GET_LOCATION,
  ILocationInfo,
  LOADING_LOCATION,
  LocationActionsDispatchTypes,
  LOCATION_LOADED,
  SET_LOCATION_SELECTED,
} from "./locationActionsTypes";
import { AlertEnum } from "actions/alertActions/alertActionTypes";
import { showAlert } from "actions/alertActions";
import { FleetLocationServices } from "services/FleetLocationServices";

export const getLocation = (id: string) => async (
  dispatch: Dispatch<LocationActionsDispatchTypes>,
  getState: any,
) => {
  dispatch({ type: LOADING_LOCATION });
  if (id === "all") {
    let data: any = getState().locations.chosenLocation;
    if (getState().locationAddresses) {
      getState().locationAddresses.map(async (item: any) => {
        if (data.length > 0) {
          let location = await FleetLocationServices.getLocationById(
            item.locationId,
          );
          let count = 0;
          data.map((item: any) => {
            if (item._id === location._id) {
              count++;
            }
            return null;
          });
          if (count === 0) {
            data.push({ ...location });
          }
        } else {
          let location = await FleetLocationServices.getLocationById(
            item.locationId,
          );
          data.push({ ...location });
        }
      });
    }
    dispatch({
      type: GET_LOCATION,
      payload: data,
    });
    dispatch({ type: LOCATION_LOADED });
  } else {
    if (id.length !== 0) {
      try {
        let location = await FleetLocationServices.getLocationById(id);
        let data: any =
          getState().locations.chosenLocation === null
            ? []
            : getState().locations.chosenLocation;

        if (data.length > 0) {
          let count = 0;
          data.map((item: any) => {
            if (item._id === location._id) {
              count++;
            }
            return null;
          });
          if (count === 0) {
            data.push({ ...location });
          } else {
            data = data.filter((item: any) => item._id !== id);
            data.push({ ...location });
          }
        } else {
          data.push({ ...location });
        }
        dispatch({
          type: GET_LOCATION,
          payload: data,
        });
        dispatch({ type: LOCATION_LOADED });
      } catch (err) {
        dispatch({ type: LOCATION_LOADED });
        dispatch(
          showAlert("Error when trying to fetch location", AlertEnum.error),
        );
      }
    }
  }
};

export const setSelectedLocation = (id: string) => async (dispatch: any) => {
  dispatch({
    type: SET_LOCATION_SELECTED,
    payload: id,
  });
};

export const updateLocation = (
  id: string,
  newLocation: ILocationInfo,
) => async (dispatch: any) => {
  try {
    await FleetLocationServices.updateLocation(id, newLocation);
    dispatch(showAlert("Updated Successfully!", AlertEnum.success));
    await dispatch(getLocation(id));
  } catch (error) {
    dispatch(
      showAlert("Error when trying to update location", AlertEnum.error),
    );
  }
};
