import React, { useEffect, FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { RootStore } from "store";
import { updatePointReducer } from "actions/pointReducerActions";
import { getLocation } from "actions/locationActions";
import { Logo } from "./images";
import { sidebarOptions } from "./sidebarItems";
import { setSelectedLocation } from "actions/locationActions";
import UserDropdown from "../Account/UserDropdown";
import LocationsPicker from "../LocationsPicker";
import "./styles.css";
import { useStyles } from "./styles";
import { getToken } from "firebase";
import { FleetClient } from "services/FleetClient";
import "firebase";

const Sidebar: FC = ({ children }) => {
  const {
    locationAddresses,
    locations,
    fleetClient,
    pointFirebaseReducer,
  } = useSelector((state: RootStore) => state);
  const { selectedLocation } = locations;
  const location = useLocation();
  const dispatch = useDispatch();

  const classes = useStyles();

  useEffect(() => {
    console.log(locationAddresses, selectedLocation, "====");
    if (fleetClient) {
      if (locationAddresses.length > 0) {
        if (selectedLocation !== "") {
          dispatch(setSelectedLocation(selectedLocation));
          dispatch(getLocation(selectedLocation));
        } else if (selectedLocation === "") {
          dispatch(setSelectedLocation(locationAddresses[0].locationId));
        } else {
          dispatch(setSelectedLocation(locationAddresses[0].locationId));
          dispatch(getLocation(locationAddresses[0].locationId));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation, fleetClient, locationAddresses]);

  useEffect(() => {
    getToken()
      .then(async (item: any) => {
        if (fleetClient.client) {
          await FleetClient.updateToken(fleetClient?.client._id, item);
        }
      })
      .catch((e) => {
        console.log(e, "===e");
      });
  }, [fleetClient]);

  return (
    <>
      <div className={classes.layout}>
        <div className="col-auto flex flex-col justify-between">
          <div>
            <div className="mx-2 my-8 md:m-8 lg:mx-6">
              <img src={Logo} alt="logo" style={{ maxWidth: "90%" }} />
            </div>
            {sidebarOptions.map(({ link, item, iconActive, icon }, key) => (
              <div
                key={key}
                className={`border ${
                  link === location.pathname && "text-orange"
                }`}
                onClick={() => {
                  if (pointFirebaseReducer.type === item) {
                    dispatch(updatePointReducer("", false));
                  }
                }}
              >
                <Link
                  to={link}
                  className={`flex h-14 items-center sm:px-5 border-l-6 justify-center sm:justify-start ${
                    link === location.pathname
                      ? "border-orange"
                      : "border-transparent"
                  }`}
                >
                  <div className="contaiener_point_firebase">
                    {link === location.pathname ? icon : iconActive}
                    {pointFirebaseReducer.type === item ? (
                      pointFirebaseReducer.showIcon && (
                        <div className="point_firebase"></div>
                      )
                    ) : (
                      <></>
                    )}
                  </div>

                  <span className=" text-secondary font-medium text-base ml-3 hidden sm:block">
                    {item}
                  </span>
                </Link>
              </div>
            ))}
          </div>

          <div>
            <LocationsPicker addresses={locationAddresses} />
            <UserDropdown />
          </div>
        </div>
        <div
          className="col-start-2 col-end-12 h-screen py-2 pt-6 overflow-y-scroll"
          style={{ backgroundColor: "#E5E6EC" }}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
