import { AlertDispatchTypes } from "actions/alertActions/alertActionTypes";
import { LoaderDispatchTypes } from "actions/loaderActions/loaderActionTypes";
import { IAddress } from "actions/signupAction/signupActionTypes";

export const GET_LOCATION = "GET_LOCATION";
export const LOADING_LOCATION = "LOADING_LOCATION";
export const LOCATION_LOADED = "LOCATION_LOADED";
export const SET_LOCATION_SELECTED = "SET_LOCATION_SELECTED";

export type GetLocation = {
  type: typeof GET_LOCATION;
  payload: ILocationInfo;
};

export type LoadingLocation = {
  type: typeof LOADING_LOCATION;
};

export type LocationLoaded = {
  type: typeof LOCATION_LOADED;
};

interface DayOfWeek {
  enabled: boolean;
  startHour: number;
  startMinute: number;
}

interface Week {
  1: DayOfWeek;
  2: DayOfWeek;
  3: DayOfWeek;
  4: DayOfWeek;
  5: DayOfWeek;
  6: DayOfWeek;
  7: DayOfWeek;
}

interface LocationSchedule {
  typeSchedule: string; // enum: ['dayOfWeek', 'calendar']
  daysOfWeek: Week;
  calendar: [{ startTime: Date }];
}

interface Fuel {
  fuel: string; //enum: ['87', '89', '93', 'diesel']
  price: number;
  formula: string;
}
export interface Asset {
  assetId: string;
  loc: string;
  clientId: string;
  tag: string;
  nickname: string;
  vehicle: string;
  make: string;
  model: string;
  color: string;
  fuelType: string;
  gallons: string;
  service: string;
  driver: string;
  unityMaxCapacity: number;
  dateTime: number;
  qrCode: string;
  status: boolean;
  _id: string;
}

interface NotInList {
  tag: string;
  images: string[];
  dateTime: Date;
}
interface AssetObj {
  asset: Asset[];
  notInList: NotInList[];
}

export type ILocationInfo = {
  _id: string;
  name: string;
  active: boolean;
  createdAt: string;
  serviceStartTime: string;
  serviceEndTime: string;
  totalCars: number;
  maxCapacity: { type: Number; required: false; default: null };
  lastSubmit: Date | null;
  lastAmountOfGallons: number;
  assetBased: boolean;
  rentalBased: boolean;
  automatedReport: boolean;
  onDemandReport: boolean;
  showZeroGallonsOrders: boolean;
  email: [{ email: string }];
  address: IAddress;
  zipcode: string;
  assets: AssetObj;
  status: string; // [ 'active' , 'deleted']
  prices: Fuel[];
  schedule: LocationSchedule;
  // monthlyBudget: number;
  fuelingDays: number;
};

export type LocationActionsDispatchTypes =
  | GetLocation
  | AlertDispatchTypes
  | LoaderDispatchTypes
  | LoadingLocation
  | LocationLoaded;
