import React from "react";
import Modal from "@material-ui/core/Modal";
import { useStyles } from "./styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Formik, Form, Field } from "formik";
import Button from "@material-ui/core/Button";
import { TextField } from "formik-material-ui";
import { passwordResetValidation } from "./validation";
import PersonIcon from "@mui/icons-material/Person";

const AccountModal = ({ open, closeModal }: any) => {
  const classes = useStyles();

  const passwordResetInitialValues = {
    password: "",
    repeatedPassword: "",
  };

  const handleSubmit = async (values: any) => {
    console.log(values, "values");
  };

  return (
    <Modal open={open}>
      <div className={classes.root}>
        <Paper elevation={3}>
          <Typography
            variant="h5"
            align="center"
            classes={{ root: classes.headingStyles }}
          >
            <PersonIcon />
            Personal Info
          </Typography>
          <Formik
            initialValues={passwordResetInitialValues}
            onSubmit={(values, _) => {
              handleSubmit(values);
            }}
          >
            <Form className={classes.formStyles}>
              <Field
                component={TextField}
                type="text"
                name="firstName"
                variant="outlined"
                label="First Name"
                className={classes.fieldStyles}
              />
              <Field
                component={TextField}
                type="text"
                name="lastName"
                variant="outlined"
                label="Last Name"
                className={classes.fieldStyles}
              />
              <Field
                component={TextField}
                type="text"
                name="Cell"
                variant="outlined"
                label="Cell"
                className={classes.fieldStyles}
              />
              <Field
                component={TextField}
                type="text"
                name="Email"
                variant="outlined"
                label="Email"
                className={classes.fieldStyles}
              />
              <Button
                variant="contained"
                type="submit"
                classes={{
                  root: classes.buttonStyle,
                }}
              >
                Save Changes
              </Button>
            </Form>
          </Formik>
        </Paper>
      </div>
    </Modal>
  );
};

export default AccountModal;
