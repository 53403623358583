import { LocationAddress } from "actions/addressesActions/addressesActionTypes";
import { ILocationInfo } from "actions/locationActions/locationActionsTypes";
import { FuelingHistory } from "pages/Vehicles/vehicle/VehicleDetails/vehicleDetails";
import { get, put } from "../api";
export class FleetLocationServices {
  static async getLocationById(locationId: string) {
    const { data: location } = await get<ILocationInfo>(
      `fleetclients/location/${locationId}`,
    );
    return location;
  }

  static async getAddresses(userId: string) {
    const { data: locationAddresses } = await get<LocationAddress[]>(
      `fleetportal/listLocations/${userId}`,
    );
    return locationAddresses;
  }

  static async createAsset(
    accessToken: string,
    locationId: string,
    asset: ILocationAsset,
  ) {
    const data = {
      locationId,
      asset: {
        ...asset,
        fuelType: asset.fuel,
      },
    };

    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    await put("fleetportal/createAsset", { data, headers });
  }
  static async createAssetRequest(
    accessToken: string,
    locationId: string,
    asset: ILocationAsset,
  ) {
    const data = {
      locationId,
      asset: {
        ...asset,
        fuelType: asset.fuel,
      },
    };

    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    await put("fleetportal/createAssetRequest", { data, headers });
  }

  static async updateLocation(locationId: string, newLocation: ILocationInfo) {
    const { data } = await put(`fleet-location/${locationId}`, {
      data: newLocation,
    });

    return data;
  }

  static async getFuelingHistoryForAsset(
    accessToken: string,
    locationId: string,
    assetId: string,
  ) {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    const axiosConfig = {
      headers,
    };

    const { data: fuelingHistory } = await get<FuelingHistory[]>(
      `fleetportal/location/${locationId}/${assetId}`,
      axiosConfig,
    );

    return fuelingHistory;
  }

  static async updateAssetsOrders(
    locationId: any,
    assetId: any,
    showAssetOrder: any,
  ) {
    const { data } = await put(`fleet-location-order/${locationId}`, {
      data: {
        assetId,
        showAssetOrder,
      },
    });

    return data;
  }

  static async getCustomerOpddata(fleetLocationId: string) {
    const { data: location } = await get<any>(
      `fleet-location-order-opd-data/${fleetLocationId}`,
    );
    return location;
  }
}
