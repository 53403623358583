import React from "react";

import {
  Button,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";

import { TableHeaderProps } from "./interfaces";

import { useStyles } from "./styles";

const TableHeader = (props: TableHeaderProps) => {
  const {
    schema,
    orderBy,
    order,
    onActionClick,
    actionText,
    onSort,
    showbuttonActionText = true,
    showLabelTable = true,
  } = props;

  const classes = useStyles({
    background: props.backgroundHeader,
    color: props.color,
  });

  return (
    <TableHead className={classes.tHead}>
      <TableRow className={classes.tRow}>
        {schema.map(({ header, key }) => (
          <TableCell key={header} className={classes.tHeadCell}>
            <TableSortLabel
              active={orderBy === key}
              direction={orderBy === key ? order : "asc"}
              onClick={() => onSort && onSort(key)}
            >
              {header}
              {orderBy === key ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}

        <TableCell>
          {showbuttonActionText && onActionClick && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="contained"
                className={classes.addButton}
                onClick={() => onActionClick && onActionClick()}
                disabled={showLabelTable}
              >
                {actionText}
              </Button>
              {showLabelTable && (
                <label style={{ color: "white", marginLeft: "10px" }}>
                  Select a diferent option from "All locations"
                </label>
              )}
            </div>
          )}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
